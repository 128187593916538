import React from "react";
import { IoStatsChart } from "react-icons/io5";
import { TfiStatsUp } from "react-icons/tfi";
export default function AnalyticsCard({ label = "", img1, img2, count }) {
  return (
    <div className="w-full bg-gradient-to-t from-indigo-700 to-indigo-500 rounded-2xl flex flex-col p-6 gap-10 min-h-[28vh]">
      <p className="text-2xl font-semibold text-violet-200">{label}</p>
      <div className="w-full flex justify-between items-center">
        <IoStatsChart size={100} color="#cc99ff" />
        <div className="flex items-center gap-4">
          <p className="flex text-lg font-bold text-white">{count}</p>
          <TfiStatsUp size={30} color="#66ff99" />
        </div>
      </div>
    </div>
  );
}
