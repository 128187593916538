import * as actionTypes from '../ActionTypes';

export const getUserProfileData = (payload) => {
  return {
    type: actionTypes.USER_PROFILE_DATA,
    payload: payload,
  };
};

export const setUserProfileDetails = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_DETAILS,
    payload: payload,
  };
};

export const setUserAccessToken = (payload) => {
  return {
    type: actionTypes.USER_TOKEN,
    payload: payload,
  };
};
