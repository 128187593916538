import React, { useEffect, useMemo, useRef } from "react";

function timeToSeconds(timeString) {
  var [minutes, seconds] = timeString.split(":").map(Number);
  var totalSeconds = minutes * 60 + seconds;
  return totalSeconds;
}

function timeDifferenceInSeconds(fromTime, toTime) {
  var [fromMinutes, fromSeconds] = fromTime.split(":").map(Number);
  var [toMinutes, toSeconds] = toTime.split(":").map(Number);

  var totalFromSeconds = fromMinutes * 60 + fromSeconds;
  var totalToSeconds = toMinutes * 60 + toSeconds;

  var timeDifference = totalToSeconds - totalFromSeconds;
  return timeDifference;
}

function isCurrentTimeInRange(currentTime, fromTime, toTime) {
  return (
    currentTime >= convertTimeToSeconds(fromTime) && currentTime <= convertTimeToSeconds(toTime)
  );
}

function convertTimeToSeconds(timeString) {
  const [minutes, seconds] = timeString.split(":").map(Number);
  return minutes * 60 + seconds;
}

function Artist({ idx, lyric, duration, maxPreviewHeight }) {
  let { fromTime, toTime } = lyric;
  // const start = (timeToSeconds(fromTime) / duration) * 100;
  const start = (timeToSeconds(fromTime) / duration) * maxPreviewHeight;
  const difference = (timeDifferenceInSeconds(fromTime, toTime) / duration) * 100;

  if (idx === 2)
    return (
      <div
        style={{
          position: "absolute",
          height: `calc(${difference}% + 30px)`,
          // top: `${start}%`,
          top: `${start}px`,
        }}
        className={`bg-[#58CF7B] bg-opacity-30 w-full top-0 min-h-[30px]`}
      >
        <div className="w-full p-2 bg-gradient-to-b from-[#58CF7B] to-[#23BFB4]">
          <p className="text-center w-full text-[10px]">ARTIST 2</p>
        </div>
      </div>
    );

  if (idx === 3)
    return (
      <div
        style={{
          position: "absolute",
          height: `calc(${difference}% + 30px)`,
          // top: `${start}%`,
          top: `${start}px`,
        }}
        className={`bg-[#E08EBF] bg-opacity-30 w-full top-0 min-h-[30px]`}
      >
        <div className="w-full p-2 bg-gradient-to-b from-[#FE98B9] to-[#505EDC]">
          <p className="text-center w-full text-[10px]">ARTIST 3</p>
        </div>
      </div>
    );

  return (
    <div
      style={{
        position: "absolute",
        height: `calc(${difference}% + 30px)`,
        // top: `${start}%`,
        top: `${start}px`,
      }}
      className={`bg-[#6662EF] bg-opacity-30 w-full top-0 min-h-[30px]`}
    >
      <div className="w-full p-2 bg-gradient-to-b from-[#7773FA] to-[#5652E5]">
        <p className="text-center w-full text-[10px]">ARTIST {idx}</p>
      </div>
    </div>
  );
}

function LyricPreview({ noofArtists, duration, currentTime, lyricArray, filename }) {
  // console.log({ noofArtists, duration, currentTime, lyricArray });

  const maxPreviewHeight = useMemo(() => {
    let maxCards = 0;
    const TILE_HEIGHT = 32;
    const artistsObject = {};
    if (lyricArray && lyricArray?.length > 0) {
      lyricArray?.forEach((element, index) => {
        if (artistsObject[element.lyricArtist]) {
          artistsObject[element.lyricArtist] = [...artistsObject[element.lyricArtist], element];
        } else {
          artistsObject[element.lyricArtist] = [element];
        }
      });
    }

    Object.keys(artistsObject).forEach((key, index) => {
      if (maxCards < artistsObject[key]?.length) maxCards = artistsObject[key]?.length;
    });

    return TILE_HEIGHT * duration;
  }, [lyricArray, duration]);

  console.log("lyricArray", lyricArray);
  console.log("maxPreviewHeight", maxPreviewHeight);

  const indicatorStyle = {
    height: "3px",
    position: "absolute",
    width: "100%",
    background: "#7773FA",
    top: `clamp(0px, ${(currentTime / duration) * 100}%, ${maxPreviewHeight}px)`,
    transition: "top 0.5s",
    zIndex: "99",
  };

  // const containerStyle = {
  //   minHeight: `${duration * 10}px`,
  //   top: `clamp( calc(400px - 100%), ${
  //     (currentTime / duration) * 100 * -1
  //   }%, 0px)`,
  //   transition: 'top 1s',
  //   zIndex: '1',
  // };

  const containerRef = useRef();
  const movingDivRef = useRef();

  useEffect(() => {
    const updatePosition = () => {
      // Check if the moving div is out of the visible area
      const container = containerRef.current;
      const movingDiv = movingDivRef.current;
      const containerRect = container.getBoundingClientRect();
      const movingDivRect = movingDiv.getBoundingClientRect();

      // Check if the moving div is out of the visible area
      if (movingDivRect.top < containerRect.top || movingDivRect.bottom > containerRect.bottom) {
        // Scroll the container to bring the moving div into view
        movingDiv.scrollIntoView({ behavior: "smooth" });
      }
    };

    updatePosition();
  }, [currentTime]);

  return (
    <>
      <div className="w-[55%] h-full text-white text-xs relative overflow-hidden">
        <div className="flex bg-[#222434] opacity-100 border-y z-50 border-[#979797] border-opacity-20">
          {Array.from({ length: noofArtists }, (_, index) => (
            <div className="w-full p-2 border-r border-opacity-30 border-[#979797]">
              <p className="text-center w-full">ARTIST {index + 1}</p>
            </div>
          ))}
        </div>
        <div
          className="flex border-y z-10 border-[#979797] h-[90%] border-opacity-20 absolute w-full overflow-y-auto no-scrollbar"
          ref={containerRef}
        >
          <div
            className="flex border-y z-10 relative w-full"
            style={{ height: `${maxPreviewHeight}px` }}
          >
            <div style={indicatorStyle} ref={movingDivRef}></div>
            {Array.from({ length: noofArtists }, (_, index) => (
              <div className="h-full relative flex-1 border-r border-opacity-30 border-[#979797]">
                {index === 0 ? (
                  <>
                    {lyricArray &&
                      lyricArray.length &&
                      lyricArray.map((lyric) => {
                        if (lyric.lyricArtist == 1)
                          return (
                            <Artist
                              idx={1}
                              lyric={lyric}
                              duration={duration}
                              maxPreviewHeight={maxPreviewHeight}
                            />
                          );
                        // return <></>;
                      })}
                  </>
                ) : index === 1 ? (
                  <>
                    {lyricArray &&
                      lyricArray.length &&
                      lyricArray.map((lyric) => {
                        if (lyric.lyricArtist == 2)
                          return (
                            <Artist
                              idx={2}
                              lyric={lyric}
                              duration={duration}
                              maxPreviewHeight={maxPreviewHeight}
                            />
                          );
                        return <></>;
                      })}
                  </>
                ) : index === 2 ? (
                  <>
                    {lyricArray &&
                      lyricArray.length &&
                      lyricArray.map((lyric) => {
                        if (lyric.lyricArtist == 3)
                          return (
                            <Artist
                              idx={3}
                              lyric={lyric}
                              duration={duration}
                              maxPreviewHeight={maxPreviewHeight}
                            />
                          );
                        return <></>;
                      })}
                  </>
                ) : (
                  <>
                    {lyricArray &&
                      lyricArray.length &&
                      lyricArray.map((lyric) => {
                        if (lyric.lyricArtist == 4)
                          return (
                            <Artist
                              idx={1}
                              lyric={lyric}
                              duration={duration}
                              maxPreviewHeight={maxPreviewHeight}
                            />
                          );
                        return <></>;
                      })}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-full w-[40%] pb-2">
        <div
          className="flex flex-col justify-between h-full p-4 rounded-xl bg-black bg-opacity-30 overflow-y-auto no-scrollbar"
          id="lyricsContainer"
        >
          {lyricArray &&
            lyricArray.length &&
            lyricArray
              .sort((a, b) => {
                const timeA = convertTimeToSeconds(a.fromTime);
                const timeB = convertTimeToSeconds(b.fromTime);
                return timeA - timeB;
              })
              .map((lyric, index) => {
                let current = currentTime;
                let isInRange = isCurrentTimeInRange(current, lyric.fromTime, lyric.toTime);
                const lyricsElement = document.getElementById("lyrics" + index);
                const lyricsParentContiner = document.getElementById("lyricsContainer");
                if (
                  isInRange &&
                  lyricsElement?.offsetTop + lyricsElement?.clientHeight >
                    lyricsParentContiner?.clientHeight + lyricsParentContiner?.scrollTop
                ) {
                  document.getElementById("lyrics" + index)?.scrollIntoView();
                }
                return (
                  <p
                    id={"lyrics" + index}
                    className={`text-white ${isInRange ? "opacity-100" : "text-opacity-50"} `}
                  >
                    {lyric.lyricText}
                  </p>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default LyricPreview;
