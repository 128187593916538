import * as actionTypes from "../ActionTypes";

const initialState = {
  userDetails: "",
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PROFILE_DETAILS:
      console.log(action.payload);
      return {
        ...state,
        userDetails: action.payload,
      };
    case actionTypes.USER_TOKEN:
      console.log(action.payload);
      return {
        ...state,
        accessToken: action.payload,
      };
    case actionTypes.USER_RESET: {
      return { ...state, user: {} };
    }
    default:
      return state;
  }
};
