import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import GlobalLoaderReducer from "./store/GlobalLoader/GlobalLoaderReducer";
import { TrackReducer } from "./store/TrackDetails/TrackReducer";
import { LyricReducer } from "./store/TrackDetails/TrackReducer";
import { webportalAppRootSaga } from "./store";
import { UserReducer } from "./store/UserData/UserDataReducer";
import { Auth0Provider } from "@auth0/auth0-react";

const reducers = combineReducers({
  globalLoader: GlobalLoaderReducer,
  trackReducer: TrackReducer,
  lyricReducer: LyricReducer,
  userReducer: UserReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(webportalAppRootSaga);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      //   "//" +
      //   window.location.hostname +
      //   "/dashboard",
       audience: process.env.REACT_APP_AUDIENCE_URL,
    }}
    scope="openid email offline_access"
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);
