import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { adminMenusArray, userMenusArray } from "../../constants/NavbarConstants";
import Button from "./Button";

const Navbar = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="w-2/12 h-full flex flex-col items-center p-6 border-r border-white border-opacity-10">
      <NavLink to="/" className="flex w-full gap-3 items-center mb-8">
        <img
          className="h-[10vh]"
          src={"https://rhythamic-dev.s3.ap-south-1.amazonaws.com/Rhythamic+LOGO.png"}
          alt="logo"
        />
        <p className="text-white text-xl font-semibold cursor-pointer">Rhythamic</p>
      </NavLink>
      <ul className="flex w-full flex-col gap-4">
        {type !== "admin" &&
          userMenusArray?.map((menu, index) => (
            <li className="flex items-center w-full" key={"menu-item" + index}>
              <NavLink
                to={menu.route}
                className={({ isActive }) =>
                  `flex items-center w-full text-center rounded-xl px-5 py-4 text-white font-medium gap-3 text-base ${
                    isActive ? "button-gradient" : "text-opacity-60"
                  }`
                }
              >
                <menu.icon className="text-lg" /> {menu.title}
              </NavLink>
            </li>
          ))}
        {type === "admin" &&
          adminMenusArray?.map((menu, index) => (
            <li className="flex items-center w-full" key={"menu-item" + index}>
              <NavLink
                to={menu.route}
                className={({ isActive }) =>
                  `flex items-center w-full text-center rounded-xl px-5 py-4 text-white font-medium gap-3 text-base ${
                    isActive ? "button-gradient" : "text-opacity-60"
                  }`
                }
              >
                <menu.icon className="text-lg" /> {menu.title}
              </NavLink>
            </li>
          ))}
      </ul>
      {!["/", "/createTrack"].includes(location.pathname) && (
        <Button
          title={"Create Now"}
          add={true}
          className="mt-auto w-fit"
          onClick={() => navigate("/createTrack")}
        />
      )}
    </div>
  );
};

export default Navbar;
