import { useEffect, useRef, useState } from "react";
import Notification from "../../assets/icons/header/notification.svg";
import ProfilePic from "../../assets/icons/UserAvatar.svg";
import { useSelector } from "react-redux";
import { FaRegUser } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import { MdAdminPanelSettings } from "react-icons/md";
import userDataHook from "../../screens/dashboard/hooks/userDataHook";
import { useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";

const HeaderV2 = ({ setDarkMode, type = "", menuItem = "" }) => {
  const navigate = useNavigate();
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const { userDetails } = useSelector((state) => state.userReducer);
  const { onLogout } = userDataHook();
  const menuRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsProfileClicked(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationClicked(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, notificationRef]);

  console.log(menuItem, "menuItem");
  console.log(userDetails, "userDetails");

  return (
    <header className="flex justify-between px-12 py-4 border-b border-white border-opacity-10 ">
      <div className="flex w-4/12 border-2 rounded-full border-white border-opacity-10 px-5 py-0.5 gap-6 items-center text-white">
        <input
          type="text"
          placeholder="Search Your Favourite Tracks..."
          className="outline-none bg-transparent flex-1 text-sm px-2"
        />{" "}
        <RiSearch2Line className="text-2xl cursor-pointer text-white/60" />
      </div>
      <div className="flex gap-6 items-center relative ">
        <img
          src={Notification}
          alt="notification"
          className="cursor-pointer"
          onClick={() => {
            setIsProfileClicked(false);
            setIsNotificationClicked(!isNotificationClicked);
          }}
        />
        <div className="flex items-center gap-3">
          <img
            src={userDetails?.profilePicUrl ? userDetails?.profilePicUrl : ProfilePic}
            alt="profile"
            className="w-[48px] h-[48px] overflow-hidden rounded-full object-cover cursor-pointer"
            onClick={() => {
              setIsProfileClicked(!isProfileClicked);
              setIsNotificationClicked(false);
            }}
          />
          {userDetails && (
            <div className="flex flex-col text-white">
              <p className="font-semibold">
                {userDetails?.firstName} {userDetails?.lastName}
              </p>
              <p className="text-white/70 text-sm">{userDetails?.email}</p>
            </div>
          )}
        </div>

        {isProfileClicked && (
          <div
            ref={menuRef}
            className="flex z-10 text-white border border-slate-600 flex-col absolute right-0 top-full mt-5 rounded-lg p-2 gap-2 bg-[#141227] shadow-sm"
          >
            <div
              className="cursor-pointer text-[#8A8CB3] hover:text-white p-2 flex gap-2 items-center justify-between"
              onClick={() => navigate("/profile")}
            >
              <FaRegUser className="text-lg cursor-pointer" />
              <p>Profile</p>
            </div>
            <span className="w-full h-[1px] bg-slate-600" />
            <div
              className="cursor-pointer text-[#8A8CB3] hover:text-white p-2 flex gap-2 items-center justify-between"
              onClick={() => navigate("/admin")}
            >
              <MdAdminPanelSettings size={25} className="text-lg cursor-pointer" />
              <p>Admin</p>
            </div>
            <span className="w-full h-[1px] bg-slate-600" />
            <div
              onClick={onLogout}
              className="cursor-pointer text-[#8A8CB3] hover:text-white p-2 flex gap-2 items-center justify-between"
            >
              <FiLogOut className=" text-lg cursor-pointer" />
              <p>Logout</p>
            </div>
          </div>
        )}

        {isNotificationClicked && (
          <div
            ref={notificationRef}
            className="flex z-50 text-white flex-col absolute right-10 top-full mt-5 rounded-lg gap-1 bg-[#141227] shadow-sm items-center"
          >
            <div className="border-[#8A8CB3] border text-center cursor-pointer w-max  border-opacity-50  text-[#8A8CB3] p-8 flex flex-col rounded-md  gap-1 items-start justify-between">
              <p className=" w-[20vw]">No Notifications</p>
              {/* <p className=" w-[20vw] text-sm">1 hour ago</p> */}
            </div>
            {/* <div className="border-[#8A8CB3] border-b-2  cursor-pointer w-max  border-opacity-50  text-[#8A8CB3] p-4 flex flex-col  gap-1 items-start justify-between">
              <p className=" w-[20vw]">
                Lorem ipsum dolor sit amet, sit amet, consectetur.
              </p>
              <p className=" w-[20vw] text-sm">1 day ago</p>
            </div> */}

            {/* <div className="text-[#8A8CB3] p-4 cursor-pointer w-full text-center">
              View All
            </div> */}
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderV2;
