import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import OtpForm from "./OtpForm";
import EmailVerification from "./EmailVerification";
import { LoginPageHooks } from "./hooks/LoginHooks";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useUserDataHook from "../dashboard/hooks/userDataHook";

const LoginPage = () => {
  const navigate = useNavigate();
  const { onLogout } = useUserDataHook();
  const { currentForm, setCurrentForm } = LoginPageHooks();

  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <div className="bg-image-class flex flex-col gap-5 items-center justify-center bg-slate-800 h-[100vh] w-full">
      {/* <div className="h-[100vh] w-1/2"></div>
      <div className="flex justify-center items-center h-[100vh] w-1/2">
        {currentForm === "login" && (
          <LoginForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "signup" && (
          <SignupForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "validate" && (
          <EmailVerification setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "forgotpassword" && (
          <ForgotPasswordForm setCurrentForm={setCurrentForm} />
        )}
        {currentForm === "otp" && <OtpForm setCurrentForm={setCurrentForm} />}
      </div> */}
      <p className="text-3xl text-white font-bold">Something Went Wrong!</p>
      {!isLoading && isAuthenticated ? (
        <button
          onClick={() => navigate("/")}
          className="text-lg font-bold text-white px-[5%] bg-violet-800 py-[0.5%] bg-opacity-60 rounded-xl"
        >
          Reload
        </button>
      ) : (
        <button
          onClick={() => onLogout()}
          className="text-lg font-bold text-white px-[5%] bg-violet-800 py-[0.5%] bg-opacity-60 rounded-xl"
        >
          Logout
        </button>
      )}
    </div>
  );
};

export default withAuthenticationRequired(LoginPage);
