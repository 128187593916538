import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toastify from "../../../components/Universal/Toastify";

import {
  saveLyricsData,
  deleteEachLyric,
  activeSectionAction,
  getLyricListAction,
  updateTrackDetails,
} from "../../../store/TrackDetails/TractDetailAction";
import {
  formatDuration,
  generateRandomLyricId,
} from "../../../utils/HelperFuns";

export const TrackLyricHooks = () => {
  const dispatch = useDispatch();
  const trackData = useSelector((state) => state.trackReducer);
  // const lyricData = useSelector((state) => state.lyricReducer.lyricList);
  const [lyricArrayErr, setLyricArrayErr] = useState("");

  const initialTrackLyricValue = {
    fromTime: "",
    toTime: "",
    lyricText: "",
    lyricArtist: "",
    lyricId: "",
  };
  const errorInitialValue = {
    fromTime: "",
    toTime: "",
    lyricText: "",
    lyricArtist: "",
  };

  const [lyricsDetails, setLyricsDetails] = useState(initialTrackLyricValue);
  const [lyricArray, setLyricArray] = useState([]);
  const [error, setError] = useState(errorInitialValue);
  const [artistErr, setArtistErr] = useState([]);

  useEffect(() => {
    const initialTrackLyricArray =
      trackData.trackDetails.lyrics && trackData.trackDetails.lyrics.length
        ? trackData.trackDetails.lyrics
            .map((lyric) => {
              return {
                fromTime: lyric.fromTime,
                toTime: lyric.toTime,
                lyricText: lyric.lyricText,
                lyricArtist: lyric.lyricArtist,
                lyricId: lyric.lyricId || generateRandomLyricId(),
              };
            })
            .filter((lyric) => lyric.lyricText)
        : [];
    setLyricArray(initialTrackLyricArray);
  }, [trackData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromTime" || name === "toTime") {
      setError({ ...error, [name]: "" });
      if (event.key === "Backspace") {
        event.preventDefault();
        // Handle backspace: remove the last character from the input
        setLyricsDetails({ ...lyricsDetails, [name]: value.slice(0, -1) });
        return;
      }

      if (/^[0-5][0-9]$/.test(value)) {
        // If two valid numbers are entered, automatically add ":"
        setLyricsDetails({ ...lyricsDetails, [name]: value + ":" });
      } else {
        setLyricsDetails({ ...lyricsDetails, [name]: value });
      }
      if (value.length >= 5) {
        if (/^([0-5][0-9]:[0-5][0-9])?$/.test(value)) {
          setLyricsDetails({ ...lyricsDetails, [name]: value });
        } else
          setError({ ...error, [name]: "Enter a valid time in mm:ss format" });
      } else
        setError({ ...error, [name]: "Enter a valid time in mm:ss format" });
    } else {
      setLyricsDetails({ ...lyricsDetails, [name]: value });
      setError({ ...error, [name]: "" });
    }
  };

  const handleClick = (lyricId, currentTime, totalDuration, index) => {
    // debugger
    const updatedLyricArr = [...lyricArray];
    if (index === 0) {
      updatedLyricArr[index] = {
        fromTime: formatDuration(currentTime),
        toTime: "00:00",
        lyricText: updatedLyricArr[index].lyricText,
        lyricArtist: 0,
        lyricId,
      };
    } else {
      if (index + 1 === lyricArray.length) {
        updatedLyricArr[index - 1] = {
          ...updatedLyricArr[index - 1],
          toTime: formatDuration(currentTime - 1),
        };

        updatedLyricArr[index] = {
          fromTime: formatDuration(currentTime),
          toTime: formatDuration(totalDuration),
          lyricText: updatedLyricArr[index].lyricText,
          lyricArtist: 0,
          lyricId,
        };
      } else {
        updatedLyricArr[index - 1] = {
          ...updatedLyricArr[index - 1],
          toTime: formatDuration(currentTime - 1),
        };

        updatedLyricArr[index] = {
          fromTime: formatDuration(currentTime),
          toTime: "00:00",
          lyricText: updatedLyricArr[index].lyricText,
          lyricArtist: 0,
          lyricId,
        };
      }
    }

    setLyricArray(updatedLyricArr);
    dispatch(updateTrackDetails({ data: updatedLyricArr, field: "lyrics" }));

    // return updatedLyricArr;
    // let updatedLyricArr = [...lyricArray];
    // updatedLyricArr
    // fromTime: formatDuration(currentTime),
    // toTime: "00:00",
    // lyricText: lyric.lyricText,
    // lyricArtist: 0,
    // lyricId,
    // fromTime: formatDuration(updatedLyricArr[idx - 1].fromTime),
    // toTime: formatDuration(currentTime - 1),
    // lyricText: updatedLyricArr[idx - 1].lyricText,
    // lyricArtist: updatedLyricArr[idx - 1].lyricArtist,
    // lyricId: updatedLyricArr[idx - 1].lyricId,
  };

  const handleLyricArtistChange = (value, lyricId) => {
    setArtistErr({
      [lyricId]: "",
    });

    let updatedLyricArr = [...lyricArray];
    updatedLyricArr = updatedLyricArr.map((lyric) => {
      if (lyric.lyricId === lyricId) {
        return {
          fromTime: lyric.fromTime,
          toTime: lyric.toTime,
          lyricText: lyric.lyricText,
          lyricArtist: value,
          lyricId,
        };
      }
      return lyric;
    });
    setLyricArray(updatedLyricArr);
    dispatch(updateTrackDetails({ data: updatedLyricArr, field: "lyrics" }));
  };

  const handlePreview = (id) => {
    // dispatch(
    //   getLyricListAction({
    //     apiPayloadRequest: { _id: id },
    //     callback: onSuccessLyricList,
    //   })
    // );
  };

  const handleSubmit = () => {
    // event.preventDefault();

    let invalid = lyricArray.filter(
      (lyric) => lyric.lyricArtist === 0 || lyric.lyricArtist === "0"
    );
    // if (invalid.length) {
    //   invalid.forEach((lyric) => {
    //     setArtistErr((prev) => ({
    //       ...prev,
    //       [lyric.lyricId]: 'Please select an artist ',
    //     }));
    //   });
    //   return;
    // }

    // after successfull api call redirect toTime next page

    // dispatch(activeSectionAction(4));
    // lyricArray
    saveLyricDetail(lyricArray, trackData.id);
  };

  const saveLyricDetail = (data, id) => {
    // const body = {
    //     _id: id,
    //     lyricId: generateRandomLyricId(),
    //     fromTime: data.fromTime,
    //     toTime: data.toTime,
    //     lyricText: data.lyricText,
    //     lyricArtist: data.lyricArtist
    // }
    // dispatch(saveLyricsData({
    //     apiPayloadRequest: body,
    //     callback: onLyricSave
    // }))
    // dispatch(saveLyricsValue(body))
    data.forEach((item) => {
      const body = {
        _id: id,
        lyricId: generateRandomLyricId(),
        fromTime: item.fromTime,
        toTime: item.toTime,
        lyricText: item.lyricText,
        lyricArtist: item.lyricArtist,
      };
      dispatch(
        saveLyricsData({
          apiPayloadRequest: body,
          callback: onLyricSave,
        })
      );
    });
  };

  const handleCancelLyric = (lyricId, fromTime) => {
    const filterData = lyricArray.filter(
      (lyric) => lyric.lyricId !== lyricId || lyric.fromTime !== fromTime
    );
    setLyricArray(filterData);
    const body = {
      _id: trackData.id,
      lyricId,
    };
    dispatch(
      deleteEachLyric({
        apiPayloadRequest: body,
        callback: onLyricDelete,
      })
    );
  };

  function lyricsArraytoSentence(lyricAr) {
    // Join the array elements into a single string with new lines
    if (lyricAr && lyricAr.length) {
      let array = lyricAr.map((ly) => ly.lyricText);
      let sentence = array.join("\n");
      return sentence;
    }
    return "";
  }
  const onLyricSave = (response) => {
    if (
      response.message === "Track Category Updated Sucessfully." &&
      response.type === 1
    ) {
      setLyricsDetails(initialTrackLyricValue);
      Toastify("Lyrics saved successfully!");
      
      dispatch(updateTrackDetails({ data: lyricArray, field: "lyrics" }));
      // for now we are not fetching the list of the lyrics
      dispatch(
        getLyricListAction({
          apiPayloadRequest: { _id: trackData.id },
          callback: onSuccessLyricList,
        })
      );
    }
  };

  const onLyricDelete = (response) => {
    console.log("response of delete the track =", response);
  };

  const onSuccessLyricList = (response) => {
    // lyricData.map((lyric) => {
    //   return {
    //     fromTime: lyric.fromTime,
    //     toTime: lyric.toTime,
    //     lyricText: lyric.lyricText,
    //     lyricArtist: lyric.lyricArtist,
    //     lyricId: lyric.lyricId || generateRandomLyricId(),
    //   };
    // });
    dispatch(updateTrackDetails({ data: response.data, field: "lyrics" }));
  };

  const handleLyricEdit = (lyricId) => {
    let editableLyric = lyricArray.find((lyric) => lyric.id === lyricId);
    setLyricsDetails(editableLyric);
    let updatedLyricArray = lyricArray.filter((lyric) => lyric.id !== lyricId);
    setLyricArray(updatedLyricArray);
  };

  function processTextarea(lyrics) {
    if (lyrics) {
      let lines = lyrics.split("\n");
      lines = lines.filter((line) => line.trim() !== "");
      lines = lines.map((line) => line.replace(/\s+/g, " "));
      return lines;
    }
    return lyrics;
  }

  const handleAddLyric = (lyrics) => {
    if (!lyrics || lyrics.length === 0) {
      setLyricArrayErr("Lyrics cannot be emptyw");
      return;
    }
    let newLyricsArr = processTextarea(lyrics);
    newLyricsArr = newLyricsArr.map((lyric) => {
      return {
        fromTime: "00:00",
        toTime: "00:00",
        lyricText: lyric,
        lyricArtist: 0,
        lyricId: generateRandomLyricId(),
      };
    });
    // saveLyricDetail(newLyricsArr, trackData.id);
    setLyricArray([...newLyricsArr]);
    dispatch(updateTrackDetails({ data: newLyricsArr, field: "lyrics" }));
    dispatch(activeSectionAction(4));
  };

  return {
    lyricsDetails,
    lyricArray,
    error,
    handleInputChange,
    handleCancelLyric,
    handleClick,
    handlePreview,
    handleSubmit,
    handleLyricEdit,
    handleAddLyric,
    lyricArrayErr,
    handleLyricArtistChange,
    artistErr,
    trackData,
    lyricsArraytoSentence,
  };
};
