import React, { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import Header from "../../components/common/Header";
import DashImg1 from "../../assets/images/dashboard/DashImg.png";
import DailyFollower from "../../assets/icons/dashboard/DailyFollowers.svg";
import DastStat1 from "../../assets/icons/dashboard/dash-stat1.svg";
import DastStat2 from "../../assets/icons/dashboard/dash-stat2.svg";
import DastStat3 from "../../assets/icons/dashboard/dash-stat3.svg";
import DastStat4 from "../../assets/icons/dashboard/dash-stat4.svg";

import Active1 from "../../assets/images/dashboard/active1.png";
import Active2 from "../../assets/images/dashboard/active2.png";
import Active3 from "../../assets/images/dashboard/active3.png";
import Active4 from "../../assets/images/dashboard/active4.png";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AnalyticsCard from "../../components/common/AnalyticsCard";
import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";

const Trending = () => {
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();

  return (
    <div className="h-screen overflow-hidden flex">
      <Navbar type="admin" />
      <div className="flex flex-col flex-1">
        <HeaderV2 />
        <div className="px-12 flex flex-col gap-6">
          <div className="flex justify-between pt-10 pb-5">
            <p className="text-3xl text-white font-bold items-center">Tending</p>
            <Button title={"This week"} dropdown={true} />
          </div>
          <div className="py-6 border-b border-white border-opacity-10 flex w-full">
            <p className="w-[30%] text-sm text-white text-opacity-50">Track Name</p>
            <p className="w-[20%] text-sm text-white text-opacity-50">Recordings</p>
            <p className="w-[25%] text-sm text-white text-opacity-50">Average Recordings</p>
            <p className="w-[20%] text-sm text-white text-opacity-50">Likes</p>
            <div className="w-[5%]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trending;
