import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";

const Profile = () => {
  return (
    <div className="h-screen overflow-hidden flex">
      <Navbar />
      <div className="flex flex-col flex-1">
        <HeaderV2 />
        <div className="h-full px-12 flex flex-col gap-6 py-[2%]">
          <div className="text-white flex justify-center items-center text-2xl h-full font-semibold">
            Coming Soon
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
