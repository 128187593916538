import * as actionTypes from '../ActionTypes';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as api from './UserDataAPI';
import { actionUpdateGlobalLoaderSagaAction } from '../GlobalLoader/GlobalLoaderAction';

function* userProfileRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload;
    const response = yield call(api.getUserProfile, payload);
    console.log('response of the profile', response);
    action.payload.callback(response);
  } catch (error) {
    console.log('error =', error);
    if (error?.response) {
      console.log(error?.response);
    } else {
      console.log('Something Wrong!', error?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* UserDataWatcherSaga() {
  yield takeLatest(actionTypes.USER_PROFILE_DATA, userProfileRequestSaga);
}
