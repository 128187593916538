import Button from "../../components/common/Button";
import {
  IoColorFilterOutline,
  IoColorFilterSharp,
  IoColorWand,
  IoMusicalNotes,
  IoPause,
  IoPlay,
} from "react-icons/io5";
import { BsArrowReturnLeft, BsArrowReturnRight } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { PiFadersHorizontalLight, PiMagicWand } from "react-icons/pi";
import { useCallback, useEffect, useRef, useState } from "react";
import { TiVideo } from "react-icons/ti";
import {
  MdAddBox,
  MdAudiotrack,
  MdBlurOn,
  MdDelete,
  MdInvertColors,
  MdNoiseAware,
  MdOutlineClose,
  MdOutlineFormatSize,
} from "react-icons/md";
import { FaCross, FaPhotoVideo } from "react-icons/fa";
import { fabric } from "fabric";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { ImBrightnessContrast, ImSpinner2 } from "react-icons/im";
import "../../utils/fabric-utils";
import Toastify from "../../components/Universal/Toastify";
import ReactModal from "react-modal";
import { GiSoundOn, GiSoundWaves } from "react-icons/gi";
import { VscColorMode } from "react-icons/vsc";
import { FaArrowRotateRight } from "react-icons/fa6";

const VideoEditor = () => {
  const [duration, setDuration] = useState(30 * 1000);
  const [formFields, setFormFields] = useState({ minutes: 0, seconds: 0 });
  const [durationModalOpen, setDurationModalOpen] = useState(false);
  const [playing, setPlaying] = useState(false);
  const playingRef = useRef(null);
  const [items, setItems] = useState([]);
  const [canvas, setCanvas] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [startedTime, setStartedTime] = useState(0);
  const [startedTimePlay, setStartedTimePlay] = useState(0);
  const [currentKeyFrame, setCurrentKeyFrame] = useState(0);
  const [resources, setResources] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [ffmpeg, setFFmpeg] = useState(null);
  const [videoSplitLoading, setVideoSplitLoading] = useState(false);
  const fps = 60;
  const timelineRef = useRef();

  console.log("items", items);
  console.log("playing1", playing);

  const refreshElements = (currentItems = null) => {
    const actualItems = currentItems ?? items;
    if (!canvas) return;
    canvas.remove(...canvas?.getObjects());
    for (let index = 0; index < actualItems.length; index++) {
      const element = actualItems[index];
      console.log("element.type", element.type);
      switch (element.type) {
        case "video": {
          if (document.getElementById(element.properties.elementId) == null) continue;
          const videoElement = document.getElementById(element.properties.elementId);
          console.log("videoElement", videoElement);
          const videoObject = new fabric.CoverVideo(videoElement, {
            name: element.id,
            left: element.placement.x,
            top: element.placement.y,
            width: element.placement.width,
            height: element.placement.height,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            angle: element.placement.rotation,
            objectCaching: false,
            selectable: true,
            lockUniScaling: true,
            customFilter: element.properties.effect.type,
          });
          console.log("videoObject", videoObject);

          element.fabricObject = videoObject;
          element.properties.imageObject = videoObject;
          videoElement.width = 100;
          videoElement.height = (videoElement.videoHeight * 100) / videoElement.videoWidth;
          canvas.add(videoObject);
          canvas.on("object:modified", function (e) {
            if (!e.target) return;
            const target = e.target;
            if (target != videoObject) return;
            const placement = element.placement;
            const newPlacement = {
              ...placement,
              x: target.left ?? placement.x,
              y: target.top ?? placement.y,
              rotation: target.angle ?? placement.rotation,
              width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
              height:
                target.height && target.scaleY ? target.height * target.scaleY : placement.height,
              scaleX: 1,
              scaleY: 1,
            };
            const newElement = {
              ...element,
              placement: newPlacement,
            };
            updateEditorElement(newElement);
          });
          break;
        }
        case "image": {
          if (document.getElementById(element.properties.elementId) == null) continue;
          const imageElement = document.getElementById(element.properties.elementId);
          // const filters = [];
          // if (element.properties.effect?.type === "blackAndWhite") {
          //   filters.push(new fabric.Image.filters.Grayscale());
          // }
          const imageObject = new fabric.CoverImage(imageElement, {
            name: element.id,
            left: element.placement.x,
            top: element.placement.y,
            angle: element.placement.rotation,
            objectCaching: false,
            selectable: true,
            lockUniScaling: true,
            // filters
            // @ts-ignore
            customFilter: element.properties.effect.type,
          });
          // imageObject.applyFilters();
          element.fabricObject = imageObject;
          element.properties.imageObject = imageObject;
          const image = {
            w: imageElement.naturalWidth,
            h: imageElement.naturalHeight,
          };

          imageObject.width = image.w;
          imageObject.height = image.h;
          imageElement.width = image.w;
          imageElement.height = image.h;
          imageObject.scaleToHeight(image.w);
          imageObject.scaleToWidth(image.h);
          const toScale = {
            x: element.placement.width / image.w,
            y: element.placement.height / image.h,
          };
          imageObject.scaleX = toScale.x * element.placement.scaleX;
          imageObject.scaleY = toScale.y * element.placement.scaleY;
          canvas.add(imageObject);
          canvas.on("object:modified", function (e) {
            if (!e.target) return;
            const target = e.target;
            if (target != imageObject) return;
            const placement = element.placement;
            let fianlScale = 1;
            if (target.scaleX && target.scaleX > 0) {
              fianlScale = target.scaleX / toScale.x;
            }
            const newPlacement = {
              ...placement,
              x: target.left ?? placement.x,
              y: target.top ?? placement.y,
              rotation: target.angle ?? placement.rotation,
              scaleX: fianlScale,
              scaleY: fianlScale,
            };
            const newElement = {
              ...element,
              placement: newPlacement,
            };
            // store.updateEditorElement(newElement);
          });
          break;
        }
        case "audio": {
          break;
        }
        case "text": {
          const textObject = new fabric.Textbox(element.properties.text, {
            name: element.id,
            left: element.placement.x,
            top: element.placement.y,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            width: element.placement.width,
            height: element.placement.height,
            angle: element.placement.rotation,
            fontSize: element.properties.fontSize,
            fontWeight: element.properties.fontWeight,
            objectCaching: false,
            selectable: true,
            lockUniScaling: true,
            fill: "#ffffff",
          });
          console.log("textObject", textObject);
          console.log("canvas", canvas);
          element.fabricObject = textObject;
          canvas.add(textObject);
          canvas.on("object:modified", function (e) {
            if (!e.target) return;
            const target = e.target;
            if (target != textObject) return;
            const placement = element.placement;
            const newPlacement = {
              ...placement,
              x: target.left ?? placement.x,
              y: target.top ?? placement.y,
              rotation: target.angle ?? placement.rotation,
              width: target.width ?? placement.width,
              height: target.height ?? placement.height,
              scaleX: target.scaleX ?? placement.scaleX,
              scaleY: target.scaleY ?? placement.scaleY,
            };
            const newElement = {
              ...element,
              placement: newPlacement,
              properties: {
                ...element.properties,
                // @ts-ignore
                text: target?.text,
              },
            };
            updateEditorElement(newElement);
          });
          break;
        }
        default: {
          throw new Error("Not implemented");
        }
      }
      if (element.fabricObject) {
        element.fabricObject.on("selected", function (e) {
          setSelectedElement(element);
        });
      }
    }
    // const selectedEditorElement = store.selectedElement;
    // if (selectedEditorElement && selectedEditorElement.fabricObject) {
    //   canvas.setActiveObject(selectedEditorElement.fabricObject);
    // }
    // this.refreshAnimations();
    // this.updateTimeTo(this.currentTimeInMs);
    canvas.renderAll();
  };

  const getUid = () => {
    return Math.random().toString(36).substring(2, 9);
  };

  const addVideo = (ele) => {
    const videoElement = document.getElementById(`video-${ele.id}`);
    const videoDurationMs = videoElement.duration * 1000;
    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    console.log("aspectRatio", aspectRatio);
    const element = {
      id: getUid(),
      name: ele.name,
      type: "video",
      placement: {
        x: 0,
        y: 0,
        width: 225 * aspectRatio,
        height: 225,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      },
      timeFrame: {
        start: 0,
        end: videoDurationMs,
      },
      properties: {
        elementId: `video-${ele.id}`,
        src: videoElement.src,
        effect: {
          type: "none",
        },
      },
    };
    setItems((prev) => [...prev, element]);
    refreshElements([...items, element]);
    setSelectedElement(element);
  };

  const addAudio = (ele) => {
    const audioElement = document.getElementById(`audio-${ele.id}`);
    const audioDurationMs = audioElement.duration * 1000;
    const element = {
      id: getUid(),
      name: ele.name,
      type: "audio",
      placement: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      },
      timeFrame: {
        start: 0,
        end: audioDurationMs,
      },
      properties: {
        elementId: `audio-${ele.id}`,
        src: audioElement.src,
      },
    };
    setItems((prev) => [...prev, element]);
    refreshElements([...items, element]);
    setSelectedElement(element);
  };

  const addText = (options) => {
    const element = {
      id: getUid(),
      name: `Text ${items?.length + 1}`,
      type: "text",
      placement: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      },
      timeFrame: {
        start: 0,
        end: duration,
      },
      properties: {
        text: options.text,
        fontSize: options.fontSize,
        fontWeight: options.fontWeight,
        splittedTexts: [],
      },
    };
    setItems((prev) => [...prev, element]);
    refreshElements([...items, element]);
    setSelectedElement(element);
  };

  const deleteItem = (index) => {
    setItems((prev) => {
      const updatedItems = [...prev];
      updatedItems.splice(index, 1);
      refreshElements(updatedItems);
      return updatedItems;
    });
  };

  const currentTimeInMs = (currentKeyFrame * 1000) / fps;

  const loadFFmpeg = async () => {
    const ffmpegObj = new FFmpeg({ log: true });
    // Load FFmpeg
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd";
    await ffmpegObj.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, "text/javascript"),
    });
    setFFmpeg(ffmpegObj);
  };

  useEffect(() => {
    const canvasElement = new fabric.Canvas("canvas", {
      height: 225,
      width: 400,
      // backgroundColor: "#ededed",
    });
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "#00a0f5";
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerStrokeColor = "#0063d8";
    fabric.Object.prototype.cornerSize = 10;
    // canvas mouse down without target should deselect active object
    canvasElement.on("mouse:down", function (e) {
      if (!e.target) {
        setSelectedElement(null);
      }
    });

    setCanvas(canvasElement);
    fabric.util.requestAnimFrame(function render() {
      canvasElement.renderAll();
      fabric.util.requestAnimFrame(render);
    });
    playingRef.current = false;

    loadFFmpeg();
  }, []);

  function appendZero(value, minDigits = 2) {
    return value.toString().padStart(minDigits, "0");
  }

  function formatTimeToMinSec(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${appendZero(seconds, 2)}`;
  }

  function formatTimeToMinSecMili(time) {
    const mili = Math.floor((time % 1000) / 10);
    return formatTimeToMinSec(time / 1000) + `.${appendZero(mili, 2)}`;
  }

  const setCurrentTimeInMs = (time) => {
    setCurrentKeyFrame(Math.floor((time / 1000) * fps));
  };

  const playFrames = () => {
    console.log("playing playFrames", playing);
    console.log("playingRef.current playFrames", playingRef.current);
    if (!playingRef.current) {
      return;
    }
    const elapsedTime = Date.now() - startedTime;
    const newTime = startedTimePlay + elapsedTime;
    updateTimeTo(newTime);
    if (newTime > duration) {
      setCurrentKeyFrame(0);
      setPlaying(false);
      playingRef.current = false;
      updateVideoElements(false);
      updateAudioElements(false);
    } else {
      requestAnimationFrame(() => {
        playFrames();
      });
    }
  };

  useEffect(() => {
    if (playing) {
      requestAnimationFrame(() => {
        playFrames();
      });
    }
  }, [playing]);

  const updateTimeTo = (newTime) => {
    setCurrentTimeInMs(newTime);
    // this.animationTimeLine.seek(newTime);
    // if (canvas) {
    //   canvas.backgroundColor = this.backgroundColor;
    // }
    items.forEach((e) => {
      if (!e.fabricObject) return;
      const isInside = e.timeFrame.start <= newTime && newTime <= e.timeFrame.end;
      e.fabricObject.visible = isInside;
    });
  };

  function isHtmlVideoElement(element) {
    if (!element) return false;
    return element.tagName === "VIDEO";
  }
  function isHtmlImageElement(element) {
    if (!element) return false;
    return element.tagName === "IMG";
  }

  function isHtmlAudioElement(element) {
    if (!element) return false;
    return element.tagName === "AUDIO";
  }

  const updateEditorElement = (editorElement) => {
    setItems((prev) =>
      prev.map((element) => (element.id === editorElement.id ? editorElement : element))
    );
  };

  const updateVideoElements = (playingItem = null) => {
    const actualPlaying = playingItem !== null ? playingItem : playingRef.current;
    items
      .filter((element) => element.type === "video")
      .forEach((element) => {
        const video = document.getElementById(element.properties.elementId);
        if (isHtmlVideoElement(video)) {
          const videoTime = (currentTimeInMs - element.timeFrame.start) / 1000;
          video.currentTime = videoTime;
          console.log("playing updateVideoElements", playing);
          console.log("playingRef.current updateVideoElements", playingRef.current);
          if (actualPlaying) {
            video.play();
          } else {
            video.pause();
          }
        }
      });
  };
  const updateAudioElements = (playingItem) => {
    const actualPlaying = playingItem !== null ? playingItem : playingRef.current;
    items
      .filter((element) => element.type === "audio")
      .forEach((element) => {
        const audio = document.getElementById(element.properties.elementId);
        if (isHtmlAudioElement(audio)) {
          const audioTime = (currentTimeInMs - element.timeFrame.start) / 1000;
          audio.currentTime = audioTime;
          console.log("playing updateAudioElements", playing);
          console.log("playingRef.current updateAudioElements", playingRef.current);
          if (actualPlaying) {
            audio.play();
          } else {
            audio.pause();
          }
        }
      });
  };

  const updateEditorElementTimeFrame = (editorElement, timeFrame) => {
    if (timeFrame.start != undefined && timeFrame.start < 0) {
      timeFrame.start = 0;
    }
    if (timeFrame.end != undefined && timeFrame.end > duration) {
      timeFrame.end = duration;
    }
    const newEditorElement = {
      ...editorElement,
      timeFrame: {
        ...editorElement.timeFrame,
        ...timeFrame,
      },
    };
    updateVideoElements();
    updateAudioElements();
    updateEditorElement(newEditorElement);
    // refreshAnimations();
  };

  const exportVideo = () => {
    setPlaying((prev) => {
      playingRef.current = !prev;
      updateVideoElements(!prev);
      updateAudioElements(!prev);
      return !prev;
    });
    setStartedTime(Date.now());
    setStartedTimePlay(currentTimeInMs);
    setExporting(true);
    let mp4 = true;
    const canvas = document.getElementById("canvas");
    const stream = canvas.captureStream(90);
    const audioElements = items.filter((element) => element.type === "audio");
    const audioStreams = [];
    audioElements.forEach((audio) => {
      let ctx = new AudioContext();
      const audioElement = document.getElementById(audio.properties.elementId);
      // const audioElement = document.createElement("audio");
      audioElement.src = audio.properties.src;
      let sourceNode = ctx.createMediaElementSource(audioElement);
      let dest = ctx.createMediaStreamDestination();
      sourceNode.connect(dest);
      sourceNode.connect(ctx.destination);
      audioStreams.push(dest.stream);
    });
    audioStreams.forEach((audioStream) => {
      stream.addTrack(audioStream.getAudioTracks()[0]);
    });
    const video = document.createElement("video");
    video.srcObject = stream;
    video.height = 500;
    video.width = 800;
    video.controls = true;
    // document.body.appendChild(video);
    video.play().then(() => {
      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];
      mediaRecorder.ondataavailable = function (e) {
        chunks.push(e.data);
        console.log("data available");
      };
      mediaRecorder.onstop = async function (e) {
        const blob = new Blob(chunks, { type: "video/webm" });

        if (mp4) {
          // lets use ffmpeg to convert webm to mp4
          if (!ffmpeg) {
            Toastify("Failed to load Media Encoder!!");
            return;
          }
          const data = new Uint8Array(await blob.arrayBuffer());
          await ffmpeg.writeFile("video.webm", data);
          await ffmpeg.exec(["-y", "-i", "video.webm", "-c", "copy", "video.mp4"]);
          // await ffmpeg.exec(["-y", "-i", "video.webm", "-c:v", "libx264", "video.mp4"]);

          const output = await ffmpeg.readFile("video.mp4");
          const outputBlob = new Blob([output], { type: "video/mp4" });
          const outputUrl = URL.createObjectURL(outputBlob);
          const a = document.createElement("a");
          a.download = "video.mp4";
          a.href = outputUrl;
          a.click();
          setExporting(false);
        } else {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "video.webm";
          a.click();
        }
      };
      mediaRecorder.start();
      setTimeout(() => {
        mediaRecorder.stop();
      }, duration);
      video.remove();
    });
  };

  const addResource = (e, type) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const item = { id: getUid(), src: URL.createObjectURL(file), type, name: file.name };
    setResources((prev) => [...prev, item]);
    setTimeout(() => {
      if (type === "video") addVideo(item);
      else if (type === "audio") addAudio(item);
    }, [600]);
  };

  const handleSeek = (seek) => {
    if (playingRef.current) {
      setPlaying((prev) => {
        playingRef.current = !prev;
        return !prev;
      });
    }
    updateTimeTo(seek);
    updateVideoElements();
    updateAudioElements();
  };

  const MARKINGS = [
    {
      interval: 5000,
      color: "black",
      size: 16,
      width: 1,
    },
    {
      interval: 1000,
      color: "black",
      size: 8,
      width: 1,
    },
  ];

  // console.log("videoSplitLoading", videoSplitLoading);

  const AUDIO_EFFECTS = {
    ECHO: "ECHO",
    FADE_IN: "FADE_IN",
    NOISE_REDUCTION: "NOISE_REDUCTION",
    WHISPER: "WHISPER",
  };

  console.log("ffmpeg", ffmpeg);

  const applyAudioEffects = async (audioEffect) => {
    if (!ffmpeg) {
      Toastify("Failed to load Media Encoder!!");
      return;
    }

    const fileName = selectedElement?.name + selectedElement.id;

    // Load the file into FFmpeg
    console.log("Load the file into FFmpeg start");
    await ffmpeg.writeFile(fileName, await fetchFile(selectedElement?.properties?.src));
    console.log("Load the file into FFmpeg done");

    let audioData = null;

    console.log("effect apply on audio start");
    switch (audioEffect) {
      case AUDIO_EFFECTS.ECHO:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          "aecho=0.8:0.88:60:0.4",
          `audio-${AUDIO_EFFECTS.ECHO}-${selectedElement.id}.mp3`,
        ]);
        console.log("effect apply on audio done");

        console.log("audio read start");
        audioData = await ffmpeg.readFile(`audio-${AUDIO_EFFECTS.ECHO}-${selectedElement.id}.mp3`);
        console.log("audio read done", audioData);

        break;
      case AUDIO_EFFECTS.FADE_IN:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          "afade=t=in:ss=0:d=15",
          `audio-${AUDIO_EFFECTS.FADE_IN}-${selectedElement.id}.mp3`,
        ]);
        console.log("effect apply on audio done");

        console.log("audio read start");
        audioData = await ffmpeg.readFile(
          `audio-${AUDIO_EFFECTS.FADE_IN}-${selectedElement.id}.mp3`
        );
        console.log("audio read done", audioData);

        break;
      case AUDIO_EFFECTS.NOISE_REDUCTION:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          "asendcmd=0.0 afftdn sn start,asendcmd=0.4 afftdn sn stop,afftdn=nr=20:nf=-40",
          `audio-${AUDIO_EFFECTS.NOISE_REDUCTION}-${selectedElement.id}.mp3`,
        ]);
        console.log("effect apply on audio done");

        console.log("audio read start");
        audioData = await ffmpeg.readFile(
          `audio-${AUDIO_EFFECTS.NOISE_REDUCTION}-${selectedElement.id}.mp3`
        );
        console.log("audio read done", audioData);

        break;
      case AUDIO_EFFECTS.WHISPER:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `afftfilt="real='hypot(re,im)*cos((random(0)*2-1)*2*3.14)':imag='hypot(re,im)*sin((random(1)*2-1)*2*3.14)':win_size=128:overlap=0.8`,
          `audio-${AUDIO_EFFECTS.WHISPER}-${selectedElement.id}.mp3`,
        ]);
        console.log("effect apply on audio done");

        console.log("audio read start");
        audioData = await ffmpeg.readFile(
          `audio-${AUDIO_EFFECTS.WHISPER}-${selectedElement.id}.mp3`
        );
        console.log("audio read done", audioData);

        break;
      default:
        break;
    }
    // Create URLs for the result
    const audioBlob = new Blob([audioData.buffer], { type: "audio/mp3" });
    console.log("audioBlob", audioBlob);

    const audioItem = {
      id: getUid(),
      src: URL.createObjectURL(audioBlob),
      type: "audio",
      name: selectedElement?.name + "(audio)",
    };
    setResources((prev) => [...prev, audioItem]);
    let audioElementItem = null;
    const updatedItems = items?.map((d) => {
      if (d?.id === selectedElement.id) {
        audioElementItem = {
          ...d,
          id: audioItem.id,
          properties: { ...d?.properties, elementId: `audio-${audioItem.id}`, src: audioItem.src },
        };
        return audioElementItem;
      }
      return d;
    });
    setTimeout(() => {
      setItems((prev) => updatedItems);
      refreshElements([...updatedItems]);
      setSelectedElement(audioElementItem);
    }, 600);
  };

  const VIDEO_EFFECTS = {
    GRAYSCALE: "GRAYSCALE",
    SEPIA_TONE: "SEPIA_TONE",
    INVERT_COLORS: "INVERT_COLORS",
    VIGNETTE: "VIGNETTE",
    GAUSSIAN_BLUR: "GAUSSIAN_BLUR",
    FADE_IN: "FADE_IN",
    FADE_OUT: "FADE_OUT",
    ROTATE_RIGHT: "ROTATE_RIGHT",
    VINTAGE: "VINTAGE",
    BRIGHTNESS: "BRIGHTNESS",
  };

  const applyVideoEffects = async (videoEffect) => {
    if (!ffmpeg) {
      Toastify("Failed to load Media Encoder!!");
      return;
    }

    const fileName = selectedElement?.name + selectedElement.id;

    // Load the file into FFmpeg
    console.log("Load the file into FFmpeg start");
    await ffmpeg.writeFile(fileName, await fetchFile(selectedElement?.properties?.src));
    console.log("Load the file into FFmpeg done");

    let audioData = null;

    console.log("effect apply on video start");
    switch (videoEffect) {
      case VIDEO_EFFECTS.GRAYSCALE:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          // `hue=s=0`,
          `colorchannelmixer=.3:.4:.3:0:.3:.4:.3:0:.3:.4:.3`,
          `video-${AUDIO_EFFECTS.GRAYSCALE}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.GRAYSCALE}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.SEPIA_TONE:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `curves=preset_sepia`,
          `video-${AUDIO_EFFECTS.SEPIA_TONE}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.SEPIA_TONE}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.INVERT_COLORS:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `negate`,
          `video-${AUDIO_EFFECTS.INVERT_COLORS}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.INVERT_COLORS}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.VIGNETTE:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `vignette`,
          `video-${AUDIO_EFFECTS.VIGNETTE}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.VIGNETTE}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.GAUSSIAN_BLUR:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `gblur=sigma=10`,
          `video-${AUDIO_EFFECTS.GAUSSIAN_BLUR}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.GAUSSIAN_BLUR}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.FADE_IN:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `fade=in:0:10`,
          `video-${AUDIO_EFFECTS.FADE_IN}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.FADE_IN}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.FADE_OUT:
        console.log("effect", `fade=out:${duration / 1000 - 10}:10`);
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `fade=out:${duration / 1000 - 10}:10`,
          `video-${AUDIO_EFFECTS.FADE_OUT}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.FADE_OUT}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.ROTATE_RIGHT:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `transpose=1`,
          `video-${AUDIO_EFFECTS.ROTATE_RIGHT}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.ROTATE_RIGHT}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.VINTAGE:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `curves=vintage`,
          `video-${AUDIO_EFFECTS.VINTAGE}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.VINTAGE}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      case VIDEO_EFFECTS.BRIGHTNESS:
        await ffmpeg.exec([
          "-i",
          fileName,
          "-filter_complex",
          `eq=brightness=0.06`,
          `video-${AUDIO_EFFECTS.BRIGHTNESS}-${selectedElement.id}.mp4`,
        ]);
        console.log("effect apply on video done");

        console.log("video read start");
        audioData = await ffmpeg.readFile(
          `video-${AUDIO_EFFECTS.BRIGHTNESS}-${selectedElement.id}.mp4`
        );
        console.log("video read done", audioData);
        break;
      default:
        break;
    }
    // Create URLs for the result
    const videoBlob = new Blob([audioData.buffer], { type: "video/mp4" });
    console.log("videoBlob", videoBlob);

    const videoItem = {
      id: getUid(),
      src: URL.createObjectURL(videoBlob),
      type: "video",
      name: selectedElement?.name + "(video)",
    };
    let videoElementItem = null;
    setResources((prev) => [...prev, videoItem]);
    const updatedItems = items?.map((d) => {
      if (d?.id === selectedElement.id) {
        videoElementItem = {
          ...d,
          id: videoItem.id,
          properties: { ...d?.properties, elementId: `video-${videoItem.id}`, src: videoItem.src },
        };
        return videoElementItem;
      }
      return d;
    });
    setTimeout(() => {
      setItems((prev) => updatedItems);
      refreshElements([...updatedItems]);
      setSelectedElement(videoElementItem);
    }, 600);
  };

  const splitAudioVideo = async () => {
    if (!ffmpeg) {
      Toastify("Failed to load Media Encoder!!");
      return;
    }

    console.log("videoSplitLoading check", videoSplitLoading);
    setVideoSplitLoading(true);
    const fileName = selectedElement?.name + selectedElement.id;

    // Load the file into FFmpeg
    console.log("Load the file into FFmpeg start");
    await ffmpeg.writeFile(fileName, await fetchFile(selectedElement?.properties?.src));
    console.log("Load the file into FFmpeg done");
    // Split audio and video
    console.log("Split video start");
    await ffmpeg.exec([
      "-i",
      fileName,
      "-c:v",
      "copy",
      "-an",
      `video-only-${selectedElement.id}.mp4`,
    ]);
    console.log("Split video done");
    console.log("Split audio start");
    await ffmpeg.exec([
      "-i",
      fileName,
      // "-c:a",
      // "copy",
      "-vn",
      `audio-only-${selectedElement.id}.mp3`,
    ]);
    console.log("Split audio done");

    // Read the result
    console.log("video read start");
    const videoData = await ffmpeg.readFile(`video-only-${selectedElement.id}.mp4`);
    console.log("video read done", videoData);
    console.log("audio read start");
    const audioData = await ffmpeg.readFile(`audio-only-${selectedElement.id}.mp3`);
    console.log("audio read done", audioData);

    // Create URLs for the result
    const videoBlob = new Blob([videoData.buffer], { type: "video/mp4" });
    const audioBlob = new Blob([audioData.buffer], { type: "audio/mp3" });
    console.log("videoBlob", videoBlob);
    console.log("audioBlob", audioBlob);

    const videoItem = {
      id: getUid(),
      src: URL.createObjectURL(videoBlob),
      type: "video",
      name: selectedElement?.name + "(video)",
    };
    const audioItem = {
      id: getUid(),
      src: URL.createObjectURL(audioBlob),
      type: "audio",
      name: selectedElement?.name + "(audio)",
    };
    setResources((prev) => [...prev, videoItem, audioItem]);

    setTimeout(() => {
      const findIndex = items?.findIndex((d) => d?.id === selectedElement?.id);
      const updatedItems = [...items];

      //add video

      const videoElement = document.getElementById(`video-${videoItem.id}`);
      const videoDurationMs = videoElement.duration * 1000;
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const videoElementItem = {
        id: getUid(),
        name: videoItem.name,
        type: "video",
        placement: {
          x: 0,
          y: 0,
          width: 225 * aspectRatio,
          height: 225,
          rotation: 0,
          scaleX: 1,
          scaleY: 1,
        },
        timeFrame: {
          start: 0,
          end: videoDurationMs,
        },
        properties: {
          elementId: `video-${videoItem.id}`,
          src: videoElement.src,
          effect: {
            type: "none",
          },
        },
      };

      //add audio element

      const audioElement = document.getElementById(`audio-${audioItem.id}`);
      const audioDurationMs = audioElement.duration * 1000;
      const audioElementItem = {
        id: getUid(),
        name: audioItem.name,
        type: "audio",
        placement: {
          x: 0,
          y: 0,
          width: 100,
          height: 100,
          rotation: 0,
          scaleX: 1,
          scaleY: 1,
        },
        timeFrame: {
          start: 0,
          end: audioDurationMs,
        },
        properties: {
          elementId: `audio-${audioItem.id}`,
          src: audioElement.src,
        },
      };

      updatedItems.splice(findIndex, 1, videoElementItem, audioElementItem);
      setItems((prev) => updatedItems);
      refreshElements([...updatedItems]);
      setSelectedElement(videoElementItem);
      setVideoSplitLoading(false);
    }, 600);
  };

  return (
    <div className="h-[100vh] overflow-hidden flex">
      <div className="w-full h-full flex flex-col justify-between items-center">
        <div className="flex-1 flex flex-col md:flex-row bg-white bg-opacity-50 w-full items-center justify-between">
          <div className="bg-black/70 p-3 h-fit md:h-full text-white/70 text-sm flex flex-row w-full md:w-fit md:flex-col gap-4 overflow-x-auto">
            <div className="flex flex-col gap-1 items-center">
              <button
                className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300"
                htmlFor="videoPicker"
                onClick={() => document.getElementById("videoPicker").click()}
              >
                <FiPlus className="text-white text-lg" />
              </button>
              <label htmlFor="videoPicker">Add</label>
              <input
                className="hidden"
                type="file"
                id="videoPicker"
                onChange={(e) => addResource(e, "video")}
              />
            </div>
            <div className="flex flex-col gap-1 items-center">
              <button
                className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300"
                htmlFor="audioPicker"
                onClick={() => document.getElementById("audioPicker").click()}
              >
                <IoMusicalNotes className="text-white text-lg" />
              </button>
              <label htmlFor="audioPicker">Music</label>
              <input
                className="hidden"
                type="file"
                id="audioPicker"
                onChange={(e) => addResource(e, "audio")}
              />
            </div>
            <div className="flex flex-col gap-1 items-center">
              <button
                className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300"
                onClick={() => {
                  addText({
                    text: "Sample Text",
                    fontSize: 16,
                    fontWeight: 400,
                  });
                }}
              >
                <MdOutlineFormatSize className="text-white text-lg" />
              </button>
              <p>Text</p>
            </div>
            {/* {/* <div className="flex flex-col gap-1 items-center">
              <button
                className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300"
                disabled
              >
                <IoColorFilterOutline className="text-white text-lg" />
              </button>
              <p>Filter</p>
            </div> */}
            {selectedElement?.type === "audio" && (
              <>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyAudioEffects(AUDIO_EFFECTS.ECHO)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <GiSoundOn className="text-white text-lg" />
                  </button>
                  <p>Echo</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyAudioEffects(AUDIO_EFFECTS.FADE_IN)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <PiFadersHorizontalLight className="text-white text-lg" />
                  </button>
                  <p>Fade In</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyAudioEffects(AUDIO_EFFECTS.NOISE_REDUCTION)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <MdNoiseAware className="text-white text-lg" />
                  </button>
                  <p>Noise Reduction</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyAudioEffects(AUDIO_EFFECTS.WHISPER)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <GiSoundWaves className="text-white text-lg" />
                  </button>
                  <p>Whisper</p>
                </div>
              </>
            )}
            {(selectedElement?.type === "video" || videoSplitLoading) && (
              <>
                <div className="flex flex-col gap-1 items-center" onClick={splitAudioVideo}>
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    {videoSplitLoading ? (
                      <ImSpinner2 className="animate-spin" />
                    ) : (
                      <PiMagicWand className="text-white text-lg" />
                    )}
                  </button>
                  <p>Split</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.GRAYSCALE)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <VscColorMode className="text-white text-lg" />
                  </button>
                  <p>Grayscale</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.BRIGHTNESS)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <ImBrightnessContrast className="text-white text-lg" />
                  </button>
                  <p>Brightness</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.FADE_IN)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <PiFadersHorizontalLight className="text-white text-lg" />
                  </button>
                  <p>Fade-in</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.FADE_OUT)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <PiFadersHorizontalLight className="text-white text-lg" />
                  </button>
                  <p>Fade-out</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.GAUSSIAN_BLUR)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <MdBlurOn className="text-white text-lg" />
                  </button>
                  <p>Blur</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.INVERT_COLORS)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <MdInvertColors className="text-white text-lg" />
                  </button>
                  <p>Invert Colors</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.ROTATE_RIGHT)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <FaArrowRotateRight className="text-white text-lg" />
                  </button>
                  <p>Rotate</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.SEPIA_TONE)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <IoColorWand className="text-white text-lg" />
                  </button>
                  <p>Sepia Tone</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.VIGNETTE)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <IoColorFilterSharp className="text-white text-lg" />
                  </button>
                  <p>Vignette</p>
                </div>
                <div
                  className="flex flex-col gap-1 items-center"
                  onClick={() => applyVideoEffects(VIDEO_EFFECTS.VINTAGE)}
                >
                  <button className="bg-white/10 rounded-md flex justify-center items-center p-3 cursor-pointer hover:bg-white/20 transition-all duration-300">
                    <IoColorFilterOutline className="text-white text-lg" />
                  </button>
                  <p>Vintage</p>
                </div>
              </>
            )}
          </div>
          <div className="md:h-full w-full md:w-2/12 bg-black/30 h-0 opacity-0">
            <ul className="h-full w-full p-2 md:p-4 overflow-y-auto flex flex-row md:flex-col gap-2 max-h-[300px] md:max-h-full">
              {resources.map((element) => (
                <li
                  key={"resource" + element.id}
                  className="flex items-center gap-3 w-fit bg-black/25 p-2"
                >
                  {element.type === "video" && (
                    <>
                      <video
                        className="max-h-[100px] w-fit h-[50px] aspect-video"
                        src={element.src}
                        height={200}
                        width={200}
                        controls
                        id={`video-${element.id}`}
                      ></video>
                      <MdAddBox
                        className="text-white cursor-pointer text-xl"
                        onClick={() => addVideo(element)}
                      />
                    </>
                  )}
                  {element.type === "audio" && (
                    <>
                      <audio
                        className=""
                        src={element.src}
                        id={`audio-${element.id}`}
                        controls
                      ></audio>
                      <div className="max-h-[100px] h-[50px] aspect-video bg-slate-900 flex items-center justify-center">
                        <IoMusicalNotes className="text-white text-3xl" />
                      </div>
                      <MdAddBox
                        className="text-white cursor-pointer text-xl"
                        onClick={() => addAudio(element)}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <canvas
            className="p-[2px] h-[400px] md:h-[500px] w-[225px] md:w-[800px] aspect-video"
            id="canvas"
          />
          <div className="flex-1 h-fit md:h-full flex w-full bg-black/70 text-white/70 md:w-fit">
            <div className="w-full flex flex-col">
              <h2 className="border-b border-white/50 font-semibold w-full h-fit p-3 text-sm leading-[100%]">
                Layers
              </h2>
              <ul className="flex flex-col gap-1 py-3 px-2">
                {items.map((element, index) => {
                  return (
                    <li
                      key={element.id}
                      className={`flex justify-between items-center px-4 py-1 cursor-pointer ${
                        selectedElement?.id === element?.id ? "bg-white/10" : ""
                      }`}
                      onClick={() => setSelectedElement(element)}
                    >
                      <div className="w-fit flex gap-3 items-center text-xs">
                        {element.type === "video" && <TiVideo className="text-gray-400 text-lg" />}
                        {element.type === "audio" && (
                          <MdAudiotrack className="text-gray-400 text-lg" />
                        )}
                        {element.type === "text" && (
                          <MdOutlineFormatSize className="text-gray-400 text-xl" />
                        )}
                        {element.name}
                      </div>
                      <MdDelete
                        className="text-red-500 cursor-pointer"
                        onClick={() => deleteItem(index)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full bg-white/5 px-2">
          <div className="py-2.5 flex justify-between items-center w-full gap-4">
            <div className="bg-white text-white bg-opacity-20 rounded-lg py-1.5 px-4 text-sm flex items-center gap-3">
              {playing ? (
                <IoPause
                  className="text-[#6662EF] cursor-pointer"
                  onClick={() => {
                    setPlaying((prev) => {
                      playingRef.current = !prev;
                      updateVideoElements(!prev);
                      updateAudioElements(!prev);
                      return !prev;
                    });
                  }}
                />
              ) : (
                <IoPlay
                  className="text-[#6662EF] cursor-pointer"
                  onClick={() => {
                    setPlaying((prev) => {
                      playingRef.current = !prev;
                      updateVideoElements(!prev);
                      updateAudioElements(!prev);
                      return !prev;
                    });
                    setStartedTime(Date.now());
                    setStartedTimePlay(currentTimeInMs);
                    // requestAnimationFrame(() => {
                    //   playFrames();
                    // });
                  }}
                />
              )}{" "}
              <span>
                {formatTimeToMinSecMili(currentTimeInMs)} /{" "}
                <span
                  onClick={() => {
                    setDurationModalOpen(true);
                    setFormFields({
                      minutes: duration / 1000 > 60 ? Math.floor(duration / (1000 * 60)) : 0,
                      seconds: Math.abs((duration / 1000) % 60),
                    });
                  }}
                >
                  {formatTimeToMinSecMili(duration)}
                </span>
              </span>
            </div>
            <div className="flex justify-between items-center flex-1">
              {/* <div className="text-sm" onClick={() => {}}>
                <button className="bg-white px-3 py-2 pl-1.5 rounded-e rotate-180">
                  <BsArrowReturnRight />
                </button>
                <button className="bg-white px-3 py-2 pr-1.5 rounded-s rotate-180">
                  <BsArrowReturnLeft />
                </button>
              </div> */}
              <Button
                className="bg-blue-600 !px-6 text-sm !py-1.5 rounded-md text-white font-medium"
                title={exporting ? "Exporting...." : "Save & Export"}
                onClick={exportVideo}
              />
            </div>
          </div>
          <div
            className="min-w-full h-[25vh] flex flex-col overflow-x-auto border-t box-border relative"
            ref={timelineRef}
          >
            {/* <div className="flex">
              {[...Array(duration / 1000)]?.map((d, i) => (
                <div className="flex border-r-2 h-[20px]" key={"timeline" + i}>
                  <div className="w-[30px] border-r h-[10px]"></div>
                  <div className="w-[30px] border-r h-[10px]"></div>
                  <div className="w-[30px] border-r h-[10px]"></div>
                  <div className="w-[30px]"></div>
                </div>
              ))}
            </div> */}
            <ScaleRangeInput
              max={duration}
              value={currentTimeInMs}
              onChange={(value) => {
                handleSeek(value);
              }}
              height={30}
              markings={MARKINGS}
              backgroundColor="#201E32"
            />
            <div className="w-full flex flex-col my-3">
              {items.map((element) => {
                return (
                  <TimeFrameView
                    key={element.id}
                    element={element}
                    duration={duration}
                    updateEditorElementTimeFrame={updateEditorElementTimeFrame}
                    setSelectedElement={setSelectedElement}
                  />
                );
              })}
              <div
                className="w-[2px] bg-red-400 absolute top-0 bottom-0 z-20"
                style={{
                  left: `${(currentTimeInMs / duration) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        className="!w-full h-full flex justify-center items-center"
        isOpen={durationModalOpen}
        onRequestClose={() => setDurationModalOpen(false)}
      >
        <div className="w-9/12 p-4 h-fit bg-black text-white relative rounded-md">
          <MdOutlineClose
            className="text-black text-2xl absolute -top-6 -right-6"
            onClick={() => setDurationModalOpen(false)}
          />
          <form className="flex flex-col w-full">
            <div className="w-full flex items-center gap-3">
              <div className="flex flex-col w-1/2">
                <input
                  className="w-full px-3 py-4 text-black font-semibold"
                  placeholder="MM"
                  type="number"
                  value={formFields.minutes}
                  onChange={(e) => {
                    setFormFields((prev) => ({ ...prev, minutes: Number(e.target.value) }));
                  }}
                />
                <label>Minutes</label>
              </div>
              <div className="flex flex-col w-1/2">
                <input
                  className="w-full px-3 py-4 text-black font-semibold"
                  placeholder="SS"
                  type="number"
                  value={formFields.seconds}
                  onChange={(e) => {
                    setFormFields((prev) => ({ ...prev, seconds: Number(e.target.value) }));
                  }}
                />
                <label>Seconds</label>
              </div>
            </div>
            <Button
              title="Update"
              className="justify-center mt-6"
              onClick={() => {
                setDuration((formFields.minutes * 60 + formFields.seconds) * 1000);
                setDurationModalOpen(false);
              }}
            />
          </form>
        </div>
      </ReactModal>
    </div>
  );
};

export default VideoEditor;

const ScaleRangeInput = (props) => {
  const { max, value, onChange } = props;
  const ref = useRef(null);
  const refIsMouseDown = useRef(false);
  const [canvasSize, setCanvasSize] = useState({ width: 50, height: props.height });

  useEffect(() => {
    // update canvas size based on container size
    const handleResize = () => {
      if (ref.current) {
        setCanvasSize({
          width: ref.current.parentElement?.clientWidth ?? 50,
          height: ref.current.parentElement?.clientHeight ?? props.height,
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const canvas = ref.current;
      canvas.width = canvasSize.width;
      canvas.height = canvasSize.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillStyle = props.backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        props.markings.forEach((marking) => {
          ctx.strokeStyle = marking.color;
          ctx.lineWidth = marking.width;
          ctx.beginPath();
          for (let i = 0; i < max; i += marking.interval) {
            ctx.moveTo((i / max) * canvas.width, 0);
            ctx.lineTo((i / max) * canvas.width, marking.size);
          }
          ctx.stroke();
        });
      }
    }
  }, [props.markings, max, canvasSize]);

  const updateFromMouseEvent = (e) => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect && e?.clientX) {
      const x = e?.clientX - rect.left;
      const value = (x / canvasSize.width) * max;
      const normalizedValue = Math.min(max, Math.max(0, value));
      onChange(normalizedValue);
    }
  };

  return (
    <div
      className="relative w-full"
      onMouseDown={(e) => {
        refIsMouseDown.current = true;
        updateFromMouseEvent(e);
      }}
      onMouseUp={(e) => {
        refIsMouseDown.current = false;
      }}
      onMouseMove={(e) => {
        if (refIsMouseDown.current) {
          updateFromMouseEvent(e);
        }
      }}
      onMouseLeave={(e) => {
        refIsMouseDown.current = false;
      }}
    >
      <canvas height={props.height} ref={ref}></canvas>
      <div
        className="rounded-full w-[4px] absolute top-0 left-0"
        style={{
          height: `${props.height}px`,
          transform: `translateX(${(value / max) * canvasSize.width}px) translateX(-2px)`,
        }}
      ></div>
    </div>
  );
};

const TimeFrameView = (props) => {
  const { element, duration, setSelectedElement, updateEditorElementTimeFrame } = props;
  // const disabled = element.type === "audio";
  const disabled = false;
  const isSelected = false;
  const bgColorOnSelected = isSelected ? "bg-slate-800" : "bg-slate-600";
  const disabledCursor = disabled ? "cursor-no-drop" : "cursor-ew-resize";

  return (
    <div
      onClick={() => {
        setSelectedElement(element);
      }}
      key={element.id}
      className={`relative w-full min-h-[25px] my-1.5 ${
        isSelected ? "border-2 border-indigo-600 bg-slate-200" : ""
      }`}
    >
      <DragableView
        className="z-10"
        value={element.timeFrame.start}
        total={30000}
        disabled={disabled}
        onChange={(value) => {
          updateEditorElementTimeFrame(element, {
            start: value,
          });
        }}
      >
        <div
          className={`bg-white border-2 border-blue-400 w-[6px] h-[15px] translate-y-[50%] transform translate-x-[-50%] ${disabledCursor}`}
        ></div>
      </DragableView>

      <DragableView
        className={disabled ? "cursor-no-drop" : "cursor-col-resize"}
        value={element.timeFrame.start}
        disabled={disabled}
        style={{
          width: `${((element.timeFrame.end - element.timeFrame.start) / duration) * 100}%`,
        }}
        total={duration}
        onChange={(value) => {
          const { start, end } = element.timeFrame;
          updateEditorElementTimeFrame(element, {
            start: value,
            end: value + (end - start),
          });
        }}
      >
        <div
          className={`${bgColorOnSelected} ${
            element.type === "video"
              ? "button-gradientV3"
              : element?.type === "text"
              ? "button-gradientV2"
              : "button-gradient"
          } h-full w-full flex items-center rounded-md text-white text-xs min-w-[0px] gap-2 px-4 py-4`}
        >
          {element?.type === "text" && <MdOutlineFormatSize className="text-lg" />}
          {element?.type === "video" && <FaPhotoVideo className="text-lg" />}
          {element?.type === "audio" && <IoMusicalNotes className="text-lg" />}
          {element.name}
        </div>
      </DragableView>
      <DragableView
        className="z-10"
        disabled={disabled}
        value={element.timeFrame.end}
        total={duration}
        onChange={(value) => {
          updateEditorElementTimeFrame(element, {
            end: value,
          });
        }}
      >
        <div
          className={`bg-white border-2 border-blue-400 w-[6px] h-[15px] translate-y-[50%] transform translate-x-[-50%] ${disabledCursor}`}
        ></div>
      </DragableView>
    </div>
  );
};

const DragableView = (props) => {
  const ref = useRef({
    div: null,
    isDragging: false,
    initialMouseX: 0,
  });
  const { current: data } = ref;
  const calculateNewValue = useCallback(
    (mouseX) => {
      if (!data.div) return 0;
      const deltaX = mouseX - data.initialMouseX;
      const deltaValue = (deltaX / data.div.parentElement?.clientWidth) * props.total;
      return props.value + deltaValue;
    },
    [data, props?.total, props?.value]
  );

  const handleMouseDown = useCallback(
    (event) => {
      if (!data.div) return;
      if (props.disabled) return;
      data.isDragging = true;
      data.initialMouseX = event.clientX;
    },
    [data, props?.disabled]
  );

  const handleMouseMove = useCallback(
    (event) => {
      if (!data.div) return;
      if (!data.isDragging || !event?.clientX) return;
      data.div.style.left = `${(calculateNewValue(event.clientX) / props.total) * 100}%`;
      event.stopPropagation();
      event.preventDefault();
    },
    [data, props?.total, calculateNewValue]
  );

  const handleMouseUp = useCallback(
    (event) => {
      if (!data.div) return;
      if (!data.isDragging || !event.clientX) return;
      data.isDragging = false;
      props.onChange(calculateNewValue(event?.clientX));
      event.stopPropagation();
      event.preventDefault();
    },
    [data, props, calculateNewValue]
  );

  const handleTouchStart = useCallback(
    (event) => {
      if (!data.div) return;
      if (props.disabled || !event?.touches?.[0]?.clientX) return;
      data.isDragging = true;
      data.initialMouseX = event?.touches?.[0]?.clientX;
    },
    [data, props?.disabled]
  );

  const handleTouchMove = useCallback(
    (event) => {
      if (!data.div) return;
      if (!data.isDragging || !event?.touches?.[0]?.clientX) return;
      data.div.style.left = `${
        (calculateNewValue(event?.touches?.[0]?.clientX) / props.total) * 100
      }%`;
      event.stopPropagation();
      event.preventDefault();
    },
    [data, calculateNewValue, props?.total]
  );

  const handleTouchEnd = useCallback(
    (event) => {
      if (!data.div) return;
      if (!data.isDragging || !event?.touches?.[0]?.clientX) return;
      data.isDragging = false;
      props.onChange(calculateNewValue(event.touches[0].clientX));
      event.stopPropagation();
      event.preventDefault();
    },
    [data, calculateNewValue, props]
  );

  useEffect(() => {
    // window.addEventListener("mouseup", handleMouseUp);
    // window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      // window.removeEventListener("mouseup", handleMouseUp);
      // window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [
    // handleMouseUp,
    // handleMouseMove,
    handleTouchEnd,
    handleTouchStart,
  ]);

  return (
    <div
      ref={(r) => {
        data.div = r;
      }}
      className={`absolute height-100 ${props.className}`}
      style={{
        left: (props.value / props.total) * 100 + "%",
        top: 0,
        bottom: 0,
        ...props.style,
      }}
      // onMouseDown={handleMouseDown}
      onTouchMove={handleTouchMove}
    >
      {props.children}
    </div>
  );
};
