import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserProfileData, setUserProfileDetails } from "../../../store/UserData/UserDataAction";
import { useAuth0 } from "@auth0/auth0-react";

function useUserDataHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const onLogout = () => {
    console.log("Logged out");
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const fetchUserProfile = () => {
    dispatch(
      getUserProfileData({
        apiPayloadRequest: {},
        callback: onSuccuss,
      })
    );
  };

  const onSuccuss = (response) => {
    dispatch(setUserProfileDetails(response.data[0]));
  };

  return { onLogout, fetchUserProfile };
}

export default useUserDataHook;
