import { FaRegCircleUser } from "react-icons/fa6";
import { MdAttractions, MdLibraryMusic } from "react-icons/md";
import { RiHome5Fill } from "react-icons/ri";
import { IoMdAnalytics } from "react-icons/io";

export const adminMenusArray = [
  {
    icon: RiHome5Fill,
    route: "/admin",
    title: "Dashboard",
  },
  {
    icon: IoMdAnalytics,
    route: "/analytics",
    title: "Analytics",
  },
  {
    icon: MdAttractions,
    route: "/trending",
    title: "Trending",
  },
];

export const userMenusArray = [
  {
    icon: RiHome5Fill,
    route: "/",
    title: "Home",
  },
  {
    icon: MdLibraryMusic,
    route: "/myTracks",
    title: "My Tracks",
  },
  {
    icon: FaRegCircleUser,
    route: "/profile",
    title: "My Profile",
  },
];
