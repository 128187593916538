export function mapCategoriesToOptions(categories = [], stateCategories = []) {
  if (categories && categories.length && categories[0].value) return categories;
  const category = categories
    .map((category) => {
      const correspondingCategory = stateCategories.find(
        (stateCategory) => stateCategory._id === category.categoryObjId
      );
      return correspondingCategory
        ? {
            value: correspondingCategory._id,
            label: correspondingCategory.category_name,
          }
        : null;
    })
    .filter(Boolean);
  return category;
}

export function mapTagsToOptions(tags = [], stateTags = []) {
  if (tags && tags.length && tags[0].value) return tags;
  return tags
    .map((tag) => {
      const correspondingTag = stateTags.find(
        (stateTag) => stateTag._id === tag.tagObjId
      );
      return correspondingTag
        ? { value: correspondingTag._id, label: correspondingTag.tag }
        : null;
    })
    .filter(Boolean);
}

export function formatDuration(durationInSeconds) {
  // Ensure the input is a valid number
  if (isNaN(durationInSeconds) || durationInSeconds < 0) {
    return '00:00';
  }

  // Calculate minutes and seconds
  const minutes = Math.floor(durationInSeconds / 60);
  const remainingSeconds = Math.floor(durationInSeconds % 60);

  // Add leading zeros to minutes and seconds
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  // Return the formatted time as mm:ss
  return `${formattedMinutes}:${formattedSeconds}`;
}

  export const generateRandomLyricId = () => {
    const randomNum = Math.floor(Math.random() * 1000000);
    return randomNum;
  };