import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import * as api from "./TrackDetailAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* uploadAssetRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.uploadAsset, payload);
    console.log("response of the uploading asset =", response);
    action.payload.callback(response);
  } catch (error) {
    console.log("error =", error);
    if (error?.response) {
      console.log(error?.response);
    } else {
      console.log("Something Wrong!", error?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* trackSaveRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    console.log("running track save...");
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.trackSave, payload);
    console.log("response of the saving track =", response);
    action.payload.callback(response);
  } catch (error) {
    console.log("error =", error);
    if (error?.response) {
      console.log(error?.response);
    } else {
      console.log("Something Wrong!", error?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateTrackRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.trackUpdate, payload);
    action.payload.callback(response, payload.payload);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addTrackCategoryRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.trackCategorySave, payload.body);
    action.payload.callback(response, payload.newOptions, payload.field);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addTrackTagRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.trackTagSave, payload.body);
    action.payload.callback(response, payload.newOptions, payload.field);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCatRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.catRead, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCatRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    console.log({ payload });
    const response = yield call(api.catSave, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readTagsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.tagsRead, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveTagsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.tagsSave, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* trackLyricSaveRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.lyricSave, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* trackLyricDeleteRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.lyricDelete, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* trackLyricReadRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLyric, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readAllMyTracksRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    // const payload = action.payload.apiPayloadRequest;
    // console.log('payload of action = ', payload);
    const response = yield call(api.readAllTracks);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readTrackDetailsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTrackDetails, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteTrackRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteTrack, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* TrackDetailWatcherSaga() {
  yield takeLatest(actionTypes.UPLOAD_ASSET, uploadAssetRequestSaga);
  yield takeLatest(actionTypes.TRACK_SAVE, trackSaveRequestSaga);
  yield takeLatest(actionTypes.TRACK_UPDATE, updateTrackRequestSaga);
  yield takeLatest(actionTypes.ADD_TRACK_CATEGORY, addTrackCategoryRequestSaga);
  yield takeLatest(actionTypes.ADD_TRACK_TAG, addTrackTagRequestSaga);
  yield takeLatest(actionTypes.READ_TAGS, readTagsRequestSaga);
  yield takeLatest(actionTypes.SAVE_TAGS, saveTagsRequestSaga);
  yield takeLatest(actionTypes.READ_CAT, readCatRequestSaga);
  yield takeLatest(actionTypes.SAVE_CAT, saveCatRequestSaga);
  yield takeLatest(actionTypes.SAVE_TRACK_LYRIC, trackLyricSaveRequestSaga);
  yield takeLatest(actionTypes.DELETE_LYRIC, trackLyricDeleteRequestSaga);
  yield takeLatest(actionTypes.READ_LYRIC, trackLyricReadRequestSaga);
  yield takeLatest(actionTypes.READ_ALL_MY_TRACKS, readAllMyTracksRequestSaga);
  yield takeLatest(actionTypes.TRACK_DETAIL_REQUEST, readTrackDetailsRequestSaga);
  yield takeLatest(actionTypes.DELETE_TRACK_REQUEST, deleteTrackRequestSaga);
}
