import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";

import CreateTrack from "./screens/createTrack/CreateTrack";
import { TrackDetails } from "./screens/trackDetails/TrackDetails";
import LoginPage from "./screens/Login/LoginPage";
import MyTracks from "./screens/myTracks/MyTracks";
import AdminDashboard from "./screens/dashboard/AdminDashboard";
import Trending from "./screens/dashboard/Trending";
import Analytics from "./screens/dashboard/Analytics";
import DashboardV2 from "./screens/dashboard/DashboardV2";

import { getUserProfile } from "./store/UserData/UserDataAPI";
import { setUserAccessToken, setUserProfileDetails } from "./store/UserData/UserDataAction";
import Profile from "./screens/profile/Profile";
import SessionExpired from "./components/common/SessionExpired";
import VideoEditor from "./screens/editor/VideoEditor";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.globalLoader.loading);
  const { accessToken } = useSelector((state) => state.userReducer);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      getUserProfile().then((response) => {
        console.log("response", response);
        dispatch(setUserProfileDetails(response?.data[0]));
      });
    }
  }, [isAuthenticated, accessToken]);

  useEffect(() => {
    if (isAuthenticated && !accessToken) {
      const token = localStorage.getItem("token");
      dispatch(setUserAccessToken(token));
    }
  }, [isAuthenticated, accessToken]);

  return (
    <div
      className={
        darkMode
          ? "bg-[#141227] h-full max-h-full  w-full"
          : "bg-gray-600 bg-opacity-10 h-full w-full"
      }
    >
      {loader && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route exact path="/logout" element={<SessionExpired />} />
          <Route path="/" element={<DashboardV2 />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/trending" element={<Trending />} />
          <Route path="/myTracks" element={<MyTracks />} />
          <Route path="/editor" element={<VideoEditor />} />
          <Route path="/myTracks/edit/:id" element={<TrackDetails />} />
          <Route path="/createTrack" element={<CreateTrack />} />
          <Route path="/trackDetails" element={<TrackDetails />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
