import React from "react";
import DownArrow from "../../assets/icons/dashboard/DownArrrow.svg";

export const InputTextField = ({
  editable = true,
  label,
  name,
  value,
  placeholder,
  onChange,
  large,
  small,
  err,
  time,
  onKeyDown,
  textWrap = false,
}) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      {label && (
        <label htmlFor={name} className="text-sm text-white">
          {label}
        </label>
      )}
      <input
        readOnly={!editable}
        className={`${
          small ? "p-2" : "p-3"
        } text-white rounded-lg bg-black bg-opacity-40 placeholder:text-sm placeholder:text-white placeholder:text-opacity-50 ${
          large ? "h-40 flex" : ""
        } ${!editable && "outline-none border-none hover:outline-none hover:border-none"} ${
          textWrap && "break-all"
        }`}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown ? onKeyDown : () => {}}
      />
      <label className="text-xs text-[#ff4f4f]">{err}</label>
    </div>
  );
};

export const DropdownField = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  options,
  small,
  err,
  className = "",
}) => {
  // console.log({ options, value });
  return (
    <div className={`flex flex-col gap-2 w-full ${className}`}>
      {label && (
        <label htmlFor={name} className="text-sm text-white">
          {label}
        </label>
      )}

      <div className="flex w-full relative">
        <select
          className={`${
            small ? "p-[10px]" : "p-[14px]"
          } w-full rounded-lg bg-black bg-opacity-40 text-sm text-white appearance-none`}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        >
          {options.map((item, index) => (
            <option key={index + item} value={item} className="p-3 w-full  bg-black bg-opacity-40">
              {item}
            </option>
          ))}
        </select>
        <img className="absolute h-2 top-[40%] right-[8%]" src={DownArrow} />
      </div>
      <label className="text-xs text-[#ff4f4f]">{err}</label>
    </div>
  );
};

export const ToggleButton = ({ label, subLabel, name, currentState, setState }) => {
  const handleToggle = () => {
    setState(!currentState);
  };

  return (
    <div className="flex gap-3">
      {label && (
        <label htmlFor={name} className="text-sm text-white">
          {label}
        </label>
      )}

      <button
        name={name}
        id={name}
        className={`relative inline-block w-[42px] h-[24px] rounded-full transition duration-300 ease-in-out ${
          currentState ? "bg-violet-400" : "bg-gray-400"
        }`}
        onClick={() => {
          handleToggle();
        }}
      >
        <span
          className={`inline-block relative top-[2px] -left-[8px] w-5 h-5 rounded-full bg-white shadow transform transition duration-300 ease-in-out ${
            currentState ? "translate-x-4" : "translate-x-0"
          }`}
        ></span>
      </button>
      {subLabel && <p className="text-sm text-white text-opacity-50">{subLabel}</p>}
    </div>
  );
};
