import { all } from 'redux-saga/effects';
import LoginAndRegisterWatcherSaga from './LoginAndRegister/LoginAndRegisterWatcherSaga';
import TrackDetailWatcherSaga from './TrackDetails/TrackDetailWatcherSaga';
import UserDataWatcherSaga from './UserData/UserDataWatcherSaga';

export function* webportalAppRootSaga() {
  yield all([
    LoginAndRegisterWatcherSaga(),
    TrackDetailWatcherSaga(),
    UserDataWatcherSaga(),
  ]);
}
