import AnalyticsCard from "../../components/common/AnalyticsCard";
import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";

const AdminDashboard = () => {
  return (
    <div className="h-screen overflow-hidden flex">
      <Navbar type="admin" />
      <div className="flex flex-col flex-1">
        <HeaderV2 />
        <div className="px-12 flex flex-col gap-6 py-[2%]">
          <div className="w-full flex justify-between gap-6">
            <AnalyticsCard label="No. of Songs" count={100} />
            <AnalyticsCard label="No. of Artists" count={100} />
            <AnalyticsCard label="No. of Turns" count={100} />
            <AnalyticsCard label="No. of Users" count={100} />
          </div>
          <div className="w-full flex justify-between  gap-6">
            <AnalyticsCard label="No. of Users" count={100} />
            <AnalyticsCard label="No. of Users" count={100} />
            <AnalyticsCard label="No. of Users" count={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
