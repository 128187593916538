import { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import DashImg1 from "../../assets/images/dashboard/DashImg.png";
import DailyFollower from "../../assets/icons/dashboard/DailyFollowers.svg";
import DastStat1 from "../../assets/icons/dashboard/dash-stat1.svg";
import DastStat2 from "../../assets/icons/dashboard/dash-stat2.svg";
import DastStat3 from "../../assets/icons/dashboard/dash-stat3.svg";
import DastStat4 from "../../assets/icons/dashboard/dash-stat4.svg";

import Active1 from "../../assets/images/dashboard/active1.png";
import Active2 from "../../assets/images/dashboard/active2.png";
import Active3 from "../../assets/images/dashboard/active3.png";
import Active4 from "../../assets/images/dashboard/active4.png";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setUserAccessToken } from "../../store/UserData/UserDataAction";
import HeaderV2 from "../../components/common/HeaderV2";
import Navbar from "../../components/common/Navbar";

const DashboardV2 = ({ setDarkMode }) => {
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE_URL,
      });

      setCheckToken(token);
      setCheckToken(token);
      return token;
    } catch (error) {
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (!isLoading) {
        if (isAuthenticated) {
          if (checkToken === null) {
            const token = await getToken();
            localStorage.setItem("token", token);
            dispatch(setUserAccessToken(token));
          }
        } else {
          navigate("/login");
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, checkToken, isLoading]);

  return (
    <div className="h-screen overflow-hidden flex">
      <Navbar />
      <div className="flex flex-col flex-1">
        <HeaderV2 />
        <div className="px-12 flex-1">
          <div className=" h-[80vh] w-full my-10 mb-11 ">
            <div className="w-full h-full flex md: gap-4">
              <div className="w-7/12 h-full card-gradient rounded-3xl p-[3%] flex flex-col justify-between gap-8">
                <div className="flex">
                  <p className="text-2xl text-white font-medium">Dashboard</p>
                </div>
                <div className="h-[35vh] w-full gap-8 flex">
                  <div className="w-[25%] h-full rounded-2xl flex flex-col justify-between">
                    <p className="text-6xl text-white font-bold">290</p>
                    <div className="flex gap-5 pb-5 border-b border-white items-center">
                      <img className="h-10" src={DailyFollower} alt="Daily Follower" />
                      <p className="text-white text-sm">
                        Followers <br /> Daily
                      </p>
                    </div>
                    <img src={DashImg1} alt="refresh" />
                    <p className="text-violet-500 text-sm underline">
                      Refresh your daily stats here
                    </p>
                  </div>
                  <div className="w-[75%] bg-gradient-to-t from-indigo-700 to-indigo-500 h-full rounded-2xl"></div>
                </div>
                <div className="h-[25vh] w-full card-gradient rounded-2xl flex ">
                  <div className="flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30">
                    <div className="flex gap-3 w-full">
                      <img src={DastStat1} alt="Stats" />
                      <p className="text-sm text-white text-opacity-50">Total Views</p>
                    </div>
                    <p className="text-5xl text-white font-semibold w-full">1.5m</p>
                    <div className="px-2 w-full">
                      <div className="h-[3px] w-full bg-white bg-opacity-30 rounded">
                        <div className="h-[3px] w-[50%] bg-purple-400 rounded"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30">
                    <div className="flex gap-3 w-full">
                      <img src={DastStat2} alt="Stats" />
                      <p className="text-sm text-white text-opacity-50">Recordings</p>
                    </div>
                    <p className="text-5xl text-white font-semibold w-full">1m</p>
                    <div className="px-2 w-full">
                      <div className="h-[3px] w-full bg-white bg-opacity-30 rounded">
                        <div className="h-[3px] w-[50%] bg-pink-300 rounded"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full justify-between items-center py-8 px-4 border-r border-white border-opacity-30">
                    <div className="flex gap-3 w-full">
                      <img src={DastStat3} alt="Stats" />
                      <p className="text-sm text-white text-opacity-50">Trending</p>
                    </div>
                    <p className="text-5xl text-white font-semibold w-full">529k</p>
                    <div className="px-2 w-full">
                      <div className="h-[3px] w-full bg-white bg-opacity-30 rounded">
                        <div className="h-[3px] w-[50%] bg-green-300 rounded"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full h-full justify-between items-center py-8 px-4">
                    <div className="flex gap-3 w-full">
                      <img src={DastStat4} alt="Stats" />
                      <p className="text-sm text-white text-opacity-50">Followers</p>
                    </div>
                    <p className="text-5xl text-white font-semibold w-full">12k</p>
                    <div className="px-2 w-full">
                      <div className="h-[3px] w-full bg-white bg-opacity-30 rounded">
                        <div className="h-[3px] w-[50%] bg-orange-300 rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-5/12 gap-4">
                <div className="w-full h-full p-8 pb-12 card-gradient rounded-3xl flex flex-col justify-between  items-center">
                  <p className="text-xl font-semibold text-white">My Tracks</p>
                  <div className="w-full flex justify-center items-center">
                    <img src={Active1} className="z-40" alt="Tracks" />
                    <img src={Active2} className="ml-[-12px] z-30" alt="Tracks" />
                    <img src={Active3} className="ml-[-12px] z-20" alt="Tracks" />
                    <img src={Active4} className="ml-[-12px] z-10" alt="Tracks" />
                    <div className="h-12 w-12 rounded-full bg-[#5652E5] ml-[-12px] flex justify-center items-center">
                      <p className="text-white">+42</p>
                    </div>
                  </div>
                  <Button onClick={() => navigate("/myTracks")} title={"My Tracks"} add={false} />
                </div>
                <div className="w-full h-full p-8 pb-12 card-gradient rounded-3xl flex flex-col justify-between  items-center">
                  <p className="text-xl font-semibold text-white">Create a New Track</p>
                  <p className="text-center text-white text-opacity-50">
                    Karaoke is a type of interactive entertainment usually offered in clubs and
                    bars, where people sing along to recorded music using a microphone. The music is
                    an instrumental version of a well-known popular song.
                  </p>
                  <Button
                    title={"Create Now"}
                    add={true}
                    onClick={() => navigate("/createTrack")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardV2;
