import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import MusicV2 from "../../assets/icons/Music.svg";

const SongCard = ({ editable, track, handleTrackEdit, handleTrackPreview, handleTrackDelete }) => {
  return (
    <div className="p-2 mt-6 card-gradient rounded-2xl flex">
      <div className="w-[30%] flex gap-4">
        <div className="w-20 flex justify-center items-center aspect-square bg-slate-200 rounded-xl">
          <img src={MusicV2} alt="music" className="w-2/5" />
        </div>
        <div className="flex flex-col gap-3 justify-around">
          <p className="text-xl font-semibold text-white w-[15vw]">
            {track?.track_name ?? " No Track Name"}
          </p>
          <div className="flex gap-2">
            <div className="h-5 w-5 rounded-full bg-pink-500"></div>
            <p className="text-white text-opacity-50 text-[14px]">
              {track?.singer_name ?? "No Singer Name"}
            </p>
          </div>
        </div>
      </div>
      <div className="w-[20%] flex gap-3 p-4 items-center">
        <div className="h-2 w-2 rounded-full bg-violet-600"></div>
        <p className="text-white text-opacity-50 text-xs">0 views</p>
      </div>
      <div className="w-[25%] flex gap-3 p-4 items-center">
        <div className="h-2 w-2 rounded-full bg-green-400"></div>
        <p className="text-white text-opacity-50 text-xs">0 (0%)</p>
      </div>
      <div className="w-[20%] flex gap-3 p-4 items-center">
        <div className="h-2 w-2 rounded-full bg-violet-300"></div>
        <p className="text-white text-opacity-50 text-xs">0</p>
      </div>
      <div className="w-[5%] flex justify-around items-center text-white text-opacity-50 text-md mr-3">
        {editable && (
          <>
            {!track.lyrics && (
              <div className="cursor-pointer p-1 " onClick={() => handleTrackEdit(track._id)}>
                <MdEdit />
              </div>
            )}
            <div className="cursor-pointer p-1 text-xl" onClick={() => handleTrackPreview(track._id)}>
              <BsFillEyeFill />
            </div>
            <div className="cursor-pointer p-1 text-xl" onClick={() => handleTrackDelete(track._id)}>
              <MdDelete />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SongCard;
