import React from "react";
import Add from "../../assets/icons/add.svg";
import DownArrow from "../../assets/icons/dashboard/DownArrrow.svg";

const Button = ({ title, add, onClick, dropdown, small, type, className = "" }) => {
  return (
    <button
      onClick={onClick}
      className={` flex items-center ${
        type === 2
          ? "text-gray-500 border border-gray-500"
          : type === 3
          ? " border border-violet-500"
          : "button-gradient"
      }  ${
        dropdown
          ? "dropdown-gradient gap-10 px-5 py-4 rounded-[14px]"
          : `${small ? "px-[10%] py-[11px] rounded-[8px]" : "rounded-[14px] px-9 py-4"}  `
      } ${type === 3 ? "text-violet-500" : "text-white"}  font-medium  gap-1 ${className}`}
    >
      {add && <img className={`${small ? "h-4" : ""}`} src={Add} />}

      {title && <p className={`${small ? "text-xs" : ""}`}>{title}</p>}
      {dropdown && <img src={DownArrow} />}
    </button>
  );
};

export default Button;
