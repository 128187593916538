import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import SongsTable from "../../components/dashboard/SongsTable";
import More10 from "../../assets/icons/more10.svg";
import Less10 from "../../assets/icons/less10.svg";
import Play from "../../assets/icons/play.svg";
import Sound from "../../assets/icons/Sound.svg";
import Cross from "../../assets/icons/Cross.svg";
import Union from "../../assets/icons/Union.svg";
import LyricPreview from "../trackDetails/LyricPreview";
import { TrackLyricHooks } from "../trackDetails/hooks/TrackLyricHooks";
import { useDispatch } from "react-redux";
import { formatDuration } from "../../utils/HelperFuns";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";

function AllMyTracks() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ismute, setIsMute] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const { trackData } = TrackLyricHooks();

  let { url, trackDetails } = trackData;
  useEffect(() => {
    const accessToken = window.localStorage.getItem("token");
    // if (accessToken && accessToken !== "") {
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      setDuration(audioRef.current.duration);
      audioRef.current.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
      // audioRef.current.src = musicUrl;
      // audioRef.current.load();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleLoadedMetadata = () => {
    try {
      setDuration(audioRef.current.duration);
      // dispatch(trackDurationAction(audioRef.current.duration));
    } catch (error) {
      console.error("Error in handleLoadedMetadata:", error);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
  }, [audioRef, setDuration]);

  const skipBackward = () => {
    audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 10);
  };

  const skipForward = () => {
    audioRef.current.currentTime = Math.min(duration, audioRef.current.currentTime + 10);
  };

  const updateTime = () => {
    setCurrentTime(audioRef.current.currentTime);
    if (audioRef.current.currentTime === audioRef.current.duration) setIsPlaying(!isPlaying);
  };
  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const closeModal = () => {
    setPreviewOpen((preState) => !preState);
    setCurrentTime(0);
    setIsPlaying(false);
    setDuration(0);
  };

  console.log("trackDetails", trackDetails);
  console.log("duration", duration);

  return (
    <>
      {isLoggedIn && (
        <div className="h-screen overflow-hidden flex">
          <Navbar />
          <div className="flex flex-col flex-1">
            <HeaderV2 />
            <div className="px-12 mb-4 flex-1 h-full overflow-y-scroll">
              <SongsTable setPreviewOpen={setPreviewOpen} />
            </div>
            <Modal className="custom-modal" isOpen={previewOpen} onRequestClose={closeModal}>
              <div className="w-full h-full overflow-hidden">
                <div className="h-[25%]">
                  <div className="flex justify-between px-[5%] pt-[3%]">
                    <p className="text-lg font-medium text-white">Preview</p>
                    <img src={Cross} className="cursor-pointer" onClick={closeModal} alt="cancel" />
                  </div>
                  <div className="flex gap-5 mt-[5%] px-[5%]">
                    <img
                      src={Less10}
                      onClick={skipBackward}
                      className="cursor-pointer"
                      alt="backward"
                    />
                    <img
                      src={isPlaying ? Union : Play}
                      onClick={playPauseToggle}
                      className="cursor-pointer"
                      alt="play"
                    />
                    <img src={More10} onClick={skipForward} className="cursor-pointer" alt="skip" />

                    <div className="flex gap-2 items-center flex-1">
                      <p className="text-white text-opacity-50 text-sm">
                        {formatDuration(currentTime)}
                      </p>
                      <div className="h-[5px] flex-1 bg-white bg-opacity-50 rounded-md cursor-pointer">
                        <div
                          className="h-full bg-blue-500 rounded-md cursor-pointe"
                          style={{ width: `${(currentTime / duration) * 100}%` }}
                        ></div>
                      </div>
                      <p className="text-white text-opacity-50 text-sm">
                        {formatDuration(duration)}
                      </p>
                    </div>
                    <button
                      onClick={() => setIsMute(!ismute)}
                      className="cursor-pointer text-[#8A8CB2] text-2xl"
                    >
                      {ismute ? <HiSpeakerXMark /> : <HiSpeakerWave />}
                    </button>
                    {url && (
                      <audio
                        key={url}
                        muted={ismute}
                        ref={audioRef}
                        onTimeUpdate={updateTime}
                        onLoadedData={handleLoadedData}
                        onError={(e) => console.error("Audio error:", url)}
                      >
                        <source src={url ? url : ""} type="audio/mpeg" />
                      </audio>
                    )}
                  </div>
                </div>
                <div className="flex gap-3 h-[75%]">
                  <LyricPreview
                    noofArtists={3}
                    duration={duration}
                    currentTime={currentTime}
                    lyricArray={trackDetails.lyrics}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}

      {/* {!isLoggedIn && navigate("/")} */}
    </>
  );
}

export default AllMyTracks;
