import { useRef, useState, useEffect } from "react";
import Header from "../../components/common/Header";
import Button from "../../components/common/Button";
import Music from "../../assets/icons/Music.svg";
import Mic from "../../assets/icons/Mic.svg";
import { useNavigate } from "react-router-dom";
import { UploadAssetHooks } from "../trackDetails/hooks/TrackHooks";
import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";

const CreateTrack = () => {
  const navigate = useNavigate();
  const { handleFileChange } = UploadAssetHooks();

  const fileInputRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [musicURL, setMusicURL] = useState(null);

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFile(file);
  //   if (file) {
  //     setSelectedFile(file);
  //   }
  // };

  const handleButtonClick = () => {
    // Trigger the click event of the hidden file input element.
    fileInputRef.current.click();
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("token");
    // if (accessToken && accessToken !== "") {
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      {isLoggedIn && (
        <div className="h-[100vh] overflow-hidden flex">
          <Navbar />
          <div className="flex flex-col flex-1">
            <HeaderV2 />
            <div className="px-12 flex flex-col items-center pb-[5%] overflow-y-auto">
              <div className="flex flex-col justify-start items-center pt-[10%] w-full gap-8">
                <p className="font-bold text-5xl text-white">Create a New Track</p>
                <p className="text-center text-lg text-white text-opacity-50 max-w-[50%] ">
                  Karaoke is a type of interactive entertainment usually offered in clubs and bars,
                  where people sing along to recorded music using a microphone. The music is an
                  instrumental version of a well-known popular song.
                </p>
                <Button
                  title={"Upload Now"}
                  add={true}
                  onClick={() => {
                    handleButtonClick();
                  }}
                ></Button>
                <input
                  value={""}
                  type="file"
                  className="hidden"
                  accept=".wav, .m4a, .mp3, .ogg, .MPEG"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>
              <div className="h-[40vh] w-[55%] relative">
                <img src={Music} className="absolute h-[60%] top-[10vh] right-0" alt="music" />
                <img src={Mic} className="absolute h-[35%] bottom-0 left-[5vw]" alt="mic" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {!isLoggedIn && navigate("/")} */}
    </>
  );
};

export default CreateTrack;
