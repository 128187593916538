import { useState, useRef, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import LyricPreview from "./LyricPreview";
import Button from "../../components/common/Button";
import Navbar from "../../components/common/Navbar";
import HeaderV2 from "../../components/common/HeaderV2";
import CustomSelect from "../../components/common/CustomSelect";
import { DropdownField, InputTextField } from "../../components/common/InputFields";

import { UploadAssetHooks } from "../trackDetails/hooks/TrackHooks";
import {
  activeSectionAction,
  trackDurationAction,
} from "../../store/TrackDetails/TractDetailAction";
import { TrackLyricHooks } from "./hooks/TrackLyricHooks";
import { formatDuration } from "../../utils/HelperFuns";

import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";
import More10 from "../../assets/icons/more10.svg";
import Less10 from "../../assets/icons/less10.svg";
import Play from "../../assets/icons/play.svg";
// import Sound from "../../assets/icons/Sound.svg";
import Check from "../../assets/icons/Check.svg";
import Cross from "../../assets/icons/Cross.svg";
import Union from "../../assets/icons/Union.svg";
import ThreeDots from "../../assets/icons/ThreeDots.svg";
import SuccessMusic from "../../assets/icons/SuccessMusic.svg";

export const TrackDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const activeSection = useSelector((state) => state.trackReducer.activeSection);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ismute, setIsMute] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const dispatch = useDispatch();

  const { trackData } = UploadAssetHooks();
  // const { lyricArray } = TrackLyricHooks();

  let { id, url, filename, trackDetails } = trackData;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // console.log('trackDuration =', trackData);

  // const previewData = useSelector((state) => state.lyricReducer?.lyricList);
  // console.log('data of preview list =', previewData);

  const audioRef = useRef(null);

  if (!url) navigate(-1);

  useEffect(() => {
    const accessToken = window.localStorage.getItem("token");
    // if (accessToken && accessToken !== "") {
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  // const musicUrl = localStorage.getItem('musicURL');
  // console.log('musicUrl =', musicUrl);

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      // console.log('it is true');
      setDuration(audioRef.current.duration);
      audioRef.current.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
      // audioRef.current.src = musicUrl;
      // audioRef.current.load();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleLoadedMetadata = () => {
    try {
      // console.log('running inside this...', audioRef.current.duration);
      setDuration(audioRef.current.duration);
      dispatch(trackDurationAction(audioRef.current.duration));
    } catch (error) {
      console.error("Error in handleLoadedMetadata:", error);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
  }, [audioRef]);

  const skipBackward = () => {
    audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 10);
  };

  const skipForward = () => {
    audioRef.current.currentTime = Math.min(duration, audioRef.current.currentTime + 10);
  };

  const updateTime = () => {
    setCurrentTime(audioRef.current.currentTime);
    setCurrentDuration(audioRef.current.duration);
    if (audioRef.current.currentTime === audioRef.current.duration) setIsPlaying(!isPlaying);
  };
  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  return (
    <div className="h-screen overflow-hidden flex">
      <Navbar />
      <div className="flex flex-col flex-1">
        <HeaderV2 />
        <div className="flex-1 relative overflow-y-auto">
          <div className="px-14 py-4 flex flex-col gap-6 backdrop-blur-xl pb-10 relative h-full">
            <div className="sticky flex flex-col gap-6">
              <p className="text-2xl text-white font-bold flex justify-between items-center">
                {filename ? filename : ""}{" "}
                <img
                  className="cursor-pointer"
                  src={Cross}
                  onClick={() => {
                    params.id ? navigate(-1) : navigate("/");
                  }}
                  alt="cross"
                />
              </p>
              <div className="flex justify-between w-full gap-3">
                <img src={Less10} onClick={skipBackward} className="cursor-pointer" alt="backward"/>
                <img
                  src={isPlaying ? Union : Play}
                  onClick={playPauseToggle}
                  className="cursor-pointer"
                  alt="play"
                />
                <img src={More10} onClick={skipForward} className="cursor-pointer" alt="forward"/>
                <div className="flex gap-2 items-center flex-1">
                  <p className="text-white text-opacity-50 text-sm">
                    {formatDuration(currentTime)}
                  </p>
                  <div className="h-[5px] bg-white flex-1 bg-opacity-50 rounded-md cursor-pointer">
                    <div
                      className="h-full bg-blue-500 rounded-md cursor-pointe"
                      style={{ width: `${(currentTime / duration) * 100}%` }}
                    ></div>
                  </div>
                  <p className="text-white text-opacity-50 text-sm">{formatDuration(duration)}</p>
                </div>
                <button
                  onClick={() => setIsMute(!ismute)}
                  className="cursor-pointer text-[#8A8CB2] text-2xl"
                >
                  {ismute ? <HiSpeakerXMark /> : <HiSpeakerWave />}
                </button>
                <audio
                  muted={ismute}
                  ref={audioRef}
                  onTimeUpdate={updateTime}
                  onLoadedData={handleLoadedData}
                >
                  <source src={url ? url : ""} type="audio/mpeg" />
                </audio>
              </div>
            </div>
            <div className="flex-1">
              {activeSection === 1 ? (
                <Section1Details />
              ) : activeSection === 2 ? (
                <Section2Details />
              ) : activeSection === 3 ? (
                <Section3Details />
              ) : activeSection === 4 ? (
                <Section4Details
                  currentTime={currentTime}
                  currentDuration={currentDuration}
                  setPreviewOpen={setPreviewOpen}
                />
              ) : (
                <Section5Details
                  setSuccessOpen={setSuccessOpen}
                  setPreviewOpen={setPreviewOpen}
                  setDuration={setDuration}
                  duration={duration}
                  setCurrentTime={setCurrentTime}
                />
              )}
            </div>
          </div>
          <Modal
            className="custom-modal"
            isOpen={previewOpen}
            onRequestClose={() => setPreviewOpen((preState) => !preState)}
          >
            <div className="w-full h-full overflow-hidden">
              <div className="h-[25%]">
                <div className="flex justify-between px-[5%] pt-[3%]">
                  <p className="text-lg font-medium text-white">Preview</p>
                  <img
                    src={Cross}
                    className="cursor-pointer"
                    onClick={() => setPreviewOpen((preState) => !preState)}
                  />
                </div>
                <div className="flex justify-between mt-[5%] px-[5%]">
                  <img src={Less10} onClick={skipBackward} className="cursor-pointer" />
                  <img
                    src={isPlaying ? Union : Play}
                    onClick={playPauseToggle}
                    className="cursor-pointer"
                  />
                  <img src={More10} onClick={skipForward} className="cursor-pointer" />

                  <div className="flex gap-2 items-center">
                    <p className="text-white text-opacity-50 text-sm">
                      {formatDuration(currentTime)}
                    </p>
                    <div className="h-[5px] w-[20vw] bg-white bg-opacity-50 rounded-md cursor-pointer">
                      <div
                        className="h-full bg-blue-500 rounded-md cursor-pointe"
                        style={{ width: `${(currentTime / duration) * 100}%` }}
                      ></div>
                    </div>
                    <p className="text-white text-opacity-50 text-sm">{formatDuration(duration)}</p>
                  </div>
                  <button
                    onClick={() => setIsMute(!ismute)}
                    className="cursor-pointer text-white text-2xl"
                  >
                    {ismute ? <HiSpeakerXMark /> : <HiSpeakerWave />}
                  </button>
                  <audio
                    muted={ismute}
                    ref={audioRef}
                    onTimeUpdate={updateTime}
                    onLoadedData={handleLoadedData}
                  >
                    <source src={url && url} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
              <div className="flex gap-3 h-[75%]">
                <LyricPreview
                  noofArtists={3}
                  duration={duration}
                  currentTime={currentTime}
                  lyricArray={trackDetails.lyrics}
                />
              </div>
            </div>
          </Modal>

          <Modal
            className="success-modal"
            isOpen={successOpen}
            onRequestClose={() => setSuccessOpen((preState) => !preState)}
          >
            <img className="h-[25%]" src={SuccessMusic} />
            <p className="text-white text-3xl mt-4 font-medium">
              {params.id ? "Track Updated Succesfully" : "Track Added Successfull"}
            </p>
            <p className="text-white mt-4 mb-6 text-center text-opacity-50">
              You can find the song in the community section of Sing now! Song book and your
              profile.
            </p>
            <Button
              title={"OKAY!"}
              onClick={() => {
                setSuccessOpen((preState) => !preState);
                params.id ? navigate(-1) : navigate("/");
              }}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export const TabDetails = ({ part1, part2, section, active, done }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    // if (accessToken && accessToken !== "") {
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="flex justify-end items-center gap-5">
      <p className={`${active || done ? "text-white" : "text-white text-opacity-50"} text-right`}>
        {part1}
        <br /> {part2}
      </p>
      <div
        className={`
            w-[40px] h-[40px] rounded-full text-white 
            ${
              done
                ? "bg-gradient-to-b from-[#58CF7B] to-[#23BFB4]"
                : active
                ? "bg-gradient-to-r from-[#505EDC] to-[#FE98B9]"
                : "inactive-gradient border border-white border-opacity-20"
            }
             flex justify-center items-center
             `}
      >
        {done ? <img src={Check} /> : section}
      </div>
    </div>
  );
};

export const Section1Details = () => {
  const { trackDetails, handleFormSubmit, handleInputChange, trackData, error } =
    UploadAssetHooks();

  console.log("error", error);

  return (
    <div className="relative h-full">
      <div className="mt-5 flex h-full rounded-2xl card-gradient border-white border-opacity-20">
        <div className="relative w-2/12 h-full bg-white bg-opacity-5 rounded-l-2xl border-r border-white border-opacity-10">
          <div className="h-full translate-x-[20px] flex flex-col">
            <div className="h-full flex flex-col justify-evenly">
              <TabDetails
                part1={"Track Details"}
                part2={""}
                section={"1"}
                active={true}
                done={false}
              />
              <TabDetails
                part1={"More Details"}
                part2={""}
                section={"2"}
                active={false}
                done={false}
              />
              <TabDetails
                part1={"Add Lyrics"}
                part2={""}
                section={"3"}
                active={false}
                done={false}
              />
              <TabDetails
                part1={"Configure Lyrics"}
                part2={""}
                section={"4"}
                active={false}
                done={false}
              />
              <TabDetails
                part1={"Lyrics Timing"}
                part2={""}
                section={"5"}
                active={false}
                done={false}
              />
            </div>
          </div>
        </div>
        <form
          className="flex flex-1 flex-col justify-between w-full bg-opacity-5 h-full rounded-r-2xl"
          onSubmit={(event) => {
            handleFormSubmit(event);
          }}
        >
          <div className="flex flex-col justify-end items-center w-full h-full">
            <div className="w-[85%] h-[90%] flex flex-col gap-8">
              <div className="flex w-full gap-8">
                <InputTextField
                  label={"Enter Track Title"}
                  name={"trackTitle"}
                  placeholder={"Enter track title"}
                  onChange={handleInputChange}
                  value={trackDetails.trackTitle}
                  err={error.trackTitle}
                />
                <InputTextField
                  label={"Singer Name"}
                  name={"singerName"}
                  placeholder={"Enter Singer Name"}
                  onChange={handleInputChange}
                  value={trackDetails.singerName}
                  err={error.singerName}
                />
              </div>
              <div className="flex w-full gap-8">
                <InputTextField
                  label={"Movie Name"}
                  name={"movieName"}
                  placeholder={"Enter Movie Name"}
                  onChange={handleInputChange}
                  value={trackDetails.movieName}
                  err={error.movieName}
                />
                <InputTextField
                  label={"Director Name"}
                  name={"directorName"}
                  placeholder={"Enter Director Name"}
                  onChange={handleInputChange}
                  value={trackDetails.directorName}
                  err={error.directorName}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end border-t border-white border-opacity-10 px-8 py-3">
            <Button type="submit" title={"Next"} />
          </div>
        </form>
      </div>
    </div>
  );
};

export const Section2Details = ({}) => {
  const { addArtist, setSongBook, songBook } = UploadAssetHooks();
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.trackReducer.tags);
  const categories = useSelector((state) => state.trackReducer.categories);

  const [error, setError] = useState({ category: "", artist: "", tags: "" });
  const [artist, setArtist] = useState(songBook.artist);
  const [imageFile, setImageFile] = useState({
    file: null,
    fileName: "",
    imageUrl: "",
  });

  // useEffect(() => {
  //   setArtist(songBook.artist);
  // }, [songBook.artist]);

  const handleMultiSelectChange = (selectedValues, txt) => {
    console.log("selectedValues", selectedValues, "txt", txt);
    if (txt === "Category") {
      setSongBook((prev) => ({ ...prev, category: selectedValues }));
    } else {
      setSongBook((prev) => ({ ...prev, tags: selectedValues }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSongBook({ ...songBook, [name]: value });
    setArtist(value);
  };

  const handleImageFile = (acceptedFiles) => {
    if (acceptedFiles.size > 20e6) {
      // You can not add more than 20MB file size
    }
    const img = new Image();
    img.src = URL.createObjectURL(acceptedFiles);

    setImageFile({
      ...imageFile,
      file: acceptedFiles,
      fileName: acceptedFiles.name,
      imageUrl: URL.createObjectURL(acceptedFiles),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (artist === "0") {
      console.log("artist", artist);
      setError({ ...error, artist: "Please select artist" });
    } else {
      addArtist(artist);
    }
  };

  console.log("artist", artist);

  return (
    <div className="relative h-full">
      <div className="mt-5 flex h-full rounded-2xl card-gradient border-white border-opacity-20">
        <div className="relative w-2/12 h-full bg-white bg-opacity-5 rounded-l-2xl border-r border-white border-opacity-10">
          <div className="h-full translate-x-[20px] flex flex-col">
            <div className="h-full flex flex-col justify-evenly">
              <TabDetails
                part1={"Track Details"}
                part2={""}
                section={"1"}
                active={true}
                done={true}
              />
              <TabDetails
                part1={"More Details"}
                part2={""}
                section={"2"}
                active={true}
                done={false}
              />
              <TabDetails
                part1={"Add Lyrics"}
                part2={""}
                section={"3"}
                active={false}
                done={false}
              />
              <TabDetails
                part1={"Configure Lyrics"}
                part2={""}
                section={"4"}
                active={false}
                done={false}
              />
              <TabDetails
                part1={"Lyrics Timing"}
                part2={""}
                section={"5"}
                active={false}
                done={false}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between w-full bg-opacity-5 h-full rounded-r-2xl">
          <div className="flex flex-col justify-end items-center w-full h-full">
            <div className="w-[85%] h-[90%] flex flex-col gap-8">
              <div className="flex w-full gap-8">
                <CustomSelect
                  title={"Category"}
                  options={categories.map((r) => {
                    return { value: r._id, label: r.category_name };
                  })}
                  value={songBook.category}
                  onChangeText={(res) => handleMultiSelectChange(res, "Category")}
                  err={error.category}
                />
                <DropdownField
                  label={"Artists"}
                  className="!w-1/2"
                  name={"artist"}
                  placeholder={"Select"}
                  onChange={handleInputChange}
                  options={["0", "1", "2", "3", "4"]}
                  err={error.artist}
                  value={artist}
                />
              </div>
              <div className="flex w-full gap-8">
                <CustomSelect
                  title={"Tags"}
                  options={tags.map((r) => {
                    return { value: r._id ? r._id : r.tag, label: r.tag };
                  })}
                  value={songBook.tags}
                  onChangeText={(res) => handleMultiSelectChange(res)}
                  err={error.tags}
                />
                <div className="w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="flex justify-end border-t border-white border-opacity-10 px-8 py-3 gap-5">
            <Button title={"Back"} type={2} onClick={() => dispatch(activeSectionAction(1))} />
            <Button title={"Next"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Section3Details = () => {
  const dispatch = useDispatch();
  const { handleAddLyric, lyricArrayErr, lyricsArraytoSentence, lyricArray } = TrackLyricHooks();
  const [lyrics, setLyrics] = useState(lyricsArraytoSentence(lyricArray));

  useEffect(() => {
    setLyrics(lyricsArraytoSentence(lyricArray));
  }, [lyricArray]);

  return (
    <div className="mt-5 flex h-full rounded-2xl card-gradient border-white border-opacity-20 relative  mb-5">
      <div className="relative w-2/12 h-full bg-white bg-opacity-5 rounded-l-2xl border-r border-white border-opacity-10">
        <div className="h-full translate-x-[20px] flex flex-col">
          <div className="h-full flex flex-col justify-evenly">
            <TabDetails
              part1={"Track Details"}
              part2={""}
              section={"1"}
              active={true}
              done={true}
            />
            <TabDetails part1={"More Details"} part2={""} section={"2"} active={true} done={true} />
            <TabDetails part1={"Add Lyrics"} part2={""} section={"3"} active={true} done={false} />
            <TabDetails
              part1={"Configure Lyrics"}
              part2={""}
              section={"4"}
              active={false}
              done={false}
            />
            <TabDetails
              part1={"Lyrics Timing"}
              part2={""}
              section={"5"}
              active={false}
              done={false}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between flex-1 bg-opacity-5 h-full rounded-r-2xl">
        <div className="flex flex-col justify-end items-center w-full h-[50%] ">
          <div className="w-[85%] h-[90%] flex flex-col gap-6">
            <div className="flex flex-col gap-2 w-full relative h-[80%] mt-[7%]">
              <label htmlFor="lyrics" className="text-base text-white">
                Lyrics
              </label>
              <textarea
                id="lyrics"
                name="lyrics"
                value={lyrics}
                placeholder="Enter Lyrics"
                onChange={(e) => setLyrics(e.target.value)}
                className="p-2 resize text-white rounded-lg bg-black bg-opacity-40 placeholder:text-sm placeholder:text-white placeholder:text-opacity-50 h-48 flex min-h-[300px] max-h-[100%] min-w-[400px] max-w-[100%]"
              ></textarea>
              <label className="text-white">{lyricArrayErr}</label>
            </div>
          </div>
        </div>
        <div className="flex justify-end border-t border-white border-opacity-10 px-8 py-3 gap-5">
          <div className="flex gap-5">
            <Button title={"Back"} type={2} onClick={() => dispatch(activeSectionAction(2))} />
            <Button
              title={"Next"}
              onClick={() => {
                handleAddLyric(lyrics);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Section4Details = ({ setPreviewOpen, currentTime, currentDuration }) => {
  let dispatch = useDispatch();
  const [noofArtists, setNoOfArtist] = useState(0);
  const {
    handleClick,
    handleSubmit,
    handlePreview,
    // lyricsDetails,
    // error,
    lyricArray,
    // handleInputChange,
    // handleCancelLyric,
    // handleLyricEdit,
    artistErr,
    handleLyricArtistChange,
  } = TrackLyricHooks();

  // const data = useSelector((state) => state.lyricReducer?.lyricList);

  const { trackData } = UploadAssetHooks();
  const { id, trackDetails } = trackData;

  useEffect(() => {
    setNoOfArtist(parseInt(trackDetails.noofArtists));
  }, [trackDetails.noofArtists]);

  // const data = useSelector((state) => state.lyricReducer?.lyricList);
  return (
    <div className="mt-5 flex h-full rounded-2xl card-gradient border-white border-opacity-20 relative  mb-5">
      <div className="relative w-2/12 h-full bg-white bg-opacity-5 rounded-l-2xl border-r border-white border-opacity-10">
        <div className="h-full translate-x-[20px] flex flex-col">
          <div className="h-full flex flex-col justify-evenly">
            <TabDetails
              part1={"Track Details"}
              part2={""}
              section={"1"}
              active={true}
              done={true}
            />
            <TabDetails part1={"More Details"} part2={""} section={"2"} active={true} done={true} />
            <TabDetails part1={"Add Lyrics"} part2={""} section={"3"} active={true} done={true} />
            <TabDetails
              part1={"Configure Lyrics"}
              part2={""}
              section={"4"}
              active={true}
              done={false}
            />
            <TabDetails
              part1={"Lyrics Timing"}
              part2={""}
              section={"5"}
              active={false}
              done={false}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between flex-1 bg-opacity-5 h-full rounded-r-2xl">
        <div className="flex flex-col justify-end items-center w-full h-[87%] gap-10">
          <div className="w-[90%] h-[90%] flex flex-col gap-3">
            <div className="px-6 py-2 bg-black bg-opacity-40 text-white text-xs flex rounded-lg">
              <p className="w-[2%]">&nbsp;</p>
              <p className="w-[9%]">From</p>
              <p className="w-[9%]">To</p>
              {/* <p className='w-[17%]'>To</p> */}
              <p className="w-[41%]">Lyrics</p>

              {noofArtists === 1 ? (
                <>
                  <p className="w-[22%]">Artist1</p>
                  {/* <p className="w-[15%]">Chorus</p> */}
                  <p className="w-[22%]">Music</p>
                </>
              ) : noofArtists === 2 ? (
                <>
                  <p className="w-[14.5%]">Artist1</p>
                  <p className="w-[14.5%]">Artist2</p>
                  {/* <p className="w-[11%]">Chorus</p> */}
                  <p className="w-[14.5%]">Music</p>
                </>
              ) : noofArtists === 3 ? (
                <>
                  <p className="w-[9%]">Artist1</p>
                  <p className="w-[9%]">Artist2</p>
                  <p className="w-[9%]">Artist3</p>
                  {/* <p className="w-[7%]">Chorus</p> */}
                  <p className="w-[9%]">Music</p>
                </>
              ) : noofArtists === 4 ? (
                <>
                  <p className="w-[6%]">Artist1</p>
                  <p className="w-[6%]">Artist2</p>
                  <p className="w-[6%]">Artist3</p>
                  <p className="w-[6%]">Artist4</p>
                  {/* <p className="w-[5%]">Chorus</p> */}
                  <p className="w-[6%]">Music</p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col h-[90%] overflow-y-auto">
              {lyricArray &&
                lyricArray.length &&
                lyricArray.map((lyric, index) => {
                  return (
                    <Fragment key={"lyrics" + index}>
                      <div key={lyric.lyricId} className="flex mt-1 justify-between items-start">
                        <div className="w-[4%] flex justify-center items-center">
                          <Button
                            add={true}
                            title={""}
                            small={true}
                            onClick={() =>
                              handleClick(lyric.lyricId, currentTime, currentDuration, index)
                            }
                            className="w-full"
                          />
                        </div>
                        <div className="px-2 w-[9%]">
                          <InputTextField
                            small={true}
                            name={"fromTime"}
                            placeholder={"00:00"}
                            value={lyric.fromTime}
                            // err={error.fromTime}
                            editable={false}
                          />
                        </div>
                        <div className="px-2 w-[9%]">
                          <InputTextField
                            small={true}
                            name={"toTime"}
                            placeholder={"00:00"}
                            value={lyric.toTime}
                            // err={error.fromTime}
                            editable={false}
                          />
                        </div>
                        {/* <div className='px-2 w-[15%]'>
                          <InputTextField
                            small={true}
                            name={'toTime'}
                            placeholder={'00:00'}
                            value={lyricsDetails.toTime}
                            onChange={handleInputChange}
                            onKeyDown={handleInputChange}
                            err={error.toTime}
                            editable={false}
                          />
                        </div> */}
                        <div className="px-2 w-[50%]">
                          {/* <InputTextField
                            textWrap={true}
                            small={true}
                            name={"lyricText"}
                            placeholder={
                              "Yemaaye Idhi PrayamaaArey Ee Lokame May"
                            }
                            value={lyric.lyricText}
                            // err={error.lyricText}
                            editable={false}
                          /> */}
                          <div className="w-full text-white rounded-lg bg-black bg-opacity-40 p-2">
                            {lyric.lyricText}
                          </div>
                        </div>
                        <div className="px-2  w-[80%]">
                          {/* <DropdownField
                            small={true}
                            name={'lyricArtist'}
                            options={['0', '1', '2', '3', '4']}
                            value={lyric.lyricArtist}
                            onChange={(e) =>
                              handleLyricArtistChange(e, lyric.lyricId)
                            }
                            err={artistErr[lyric.lyricId]}
                          /> */}
                          <ArtistSelection
                            noofArtists={noofArtists}
                            lyricsId={lyric.lyricId}
                            handleLyricArtistChange={handleLyricArtistChange}
                            err={artistErr[lyric.lyricId]}
                          />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
            {/* <div className='w-full overflow-y-scroll h-[80%]'>
              {lyricArray.length > 0 &&
                lyricArray.map((eachLyric, index) => (
                  <React.Fragment key={index}>
                    <div className='flex gap-4 mt-6 items-center' key={index}>
                      <div className='px-4 py-4 text-opacity-50 text-white text-sm border border-white border-opacity-10 flex rounded-xl w-[93%]'>
                        <p className='w-[15%]'>{eachLyric.fromTime}</p>
                        <p className='w-[18%]'>{eachLyric.toTime}</p>
                        <p className='w-[52%]'>{eachLyric.lyricText}</p>
                        <p className='w-[15%]'>Artist{eachLyric.lyricArtist}</p>
                      </div>
                      <div
                        className='cursor-pointer text-slate-200 p-[5px] bg-[#343467] rounded-full text-sm'
                        onClick={() => handleLyricEdit(eachLyric.id)}
                      >
                        <MdEdit />
                      </div>
                      <img
                        className='cursor-pointer'
                        src={Cross2}
                        onClick={() =>
                          handleCancelLyric(
                            eachLyric.lyricId,
                            eachLyric.fromTime
                          )
                        }
                      />
                    </div>
                  </React.Fragment>
                ))}
            </div> */}
          </div>
        </div>
        <div className="flex justify-between border-t border-white border-opacity-10 px-8 py-3 gap-5">
          <Button
            title={"Preview"}
            type={3}
            onClick={() => {
              setPreviewOpen((preState) => !preState);
              handlePreview(id);
            }}
          />
          <div className="flex gap-5">
            <Button title={"Back"} type={2} onClick={() => dispatch(activeSectionAction(3))} />
            <Button title={"Next"} onClick={() => dispatch(activeSectionAction(5))} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Section5Details = ({
  setSuccessOpen,
  setPreviewOpen,
  setDuration,
  setCurrentTime,
  duration,
}) => {
  const dispatch = useDispatch();
  const { lyricArray, handleSubmit } = TrackLyricHooks();
  const data = lyricArray;
  // const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const { trackData } = UploadAssetHooks();
  let { url } = trackData;
  let [lyricsData, setLyricsData] = useState([]);
  console.log({ lyricArray });
  useEffect(() => {
    if (data.length) {
      let a = [];
      data.forEach((element) => {
        a.push({ ...element, isPlaying: false });
      });
      setLyricsData(a);
    }
  }, [data]);

  const playPauseToggle = (initialTime, toTime, index) => {
    let a = [...lyricsData];

    if (a[index].isPlaying) {
      audioRef.current.pause();
    } else {
      // setDuration(audioRef.current.duration = initialTime);
      const [minutes, seconds] = initialTime.split(":");
      // const [minute, second] = toTime.split(':');
      const customStartTimeInSeconds = parseInt(minutes) * 60 + parseInt(seconds);
      audioRef.current.currentTime = customStartTimeInSeconds;
      setCurrentTime(audioRef.current.currentTime);
      audioRef.current.play();
    }
    a.map((r, idx) => {
      if (index === idx) {
        return (r.isPlaying = !r.isPlaying);
      } else {
        r.isPlaying = false;
      }
    });
    setLyricsData(a);
  };

  return (
    <div className="mt-5 flex h-full rounded-2xl card-gradient border-white border-opacity-20 relative  mb-5">
      <div className="relative w-2/12 h-full bg-white bg-opacity-5 rounded-l-2xl border-r border-white border-opacity-10">
        <div className="h-full translate-x-[20px] flex flex-col">
          <div className="h-full flex flex-col justify-evenly">
            <TabDetails
              part1={"Track Details"}
              part2={""}
              section={"1"}
              active={true}
              done={true}
            />
            <TabDetails part1={"More Details"} part2={""} section={"2"} active={true} done={true} />
            <TabDetails part1={"Add Lyrics"} part2={""} section={"3"} active={true} done={true} />
            <TabDetails
              part1={"Configure Lyrics"}
              part2={""}
              section={"4"}
              active={true}
              done={true}
            />
            <TabDetails
              part1={"Lyrics Timing"}
              part2={""}
              section={"5"}
              active={true}
              done={false}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 justify-between w-full bg-opacity-5 h-full rounded-r-2xl">
        <div className="flex flex-col justify-end items-center w-full h-[90%] ">
          <div className="w-[85%] h-[90%] flex flex-col gap-6 overflow-y-auto">
            {lyricsData?.length > 0 &&
              lyricsData?.map((eachData, index) => (
                <Fragment key={index}>
                  <div className="flex flex-col gap-2" key={index}>
                    <div className="px-6 py-6 text-opacity-50 card-gradient text-white text-sm border border-white border-opacity-10 flex items-center rounded-xl w-[100%]">
                      <div className="w-[10%]">
                        <img
                          className="cursor-pointer"
                          src={eachData?.isPlaying ? Union : Play}
                          onClick={() =>
                            playPauseToggle(eachData?.fromTime, eachData?.toTime, index)
                          }
                          alt="play"
                        />
                      </div>
                      <p className="w-[20%]">{eachData?.fromTime}</p>
                      <p className="w-[20%]">{eachData?.toTime}</p>
                      <p className="w-[30%]">{eachData.lyricText}</p>
                      <p className="w-[15%]">Artist{eachData.lyricArtist}</p>
                      <div className="w-[5%] flex justify-end items-center">
                        <img className="h-4" src={ThreeDots} alt="options" />
                      </div>
                    </div>
                    <audio ref={audioRef}>
                      <source src={url ? url : ""} type="audio/mpeg" />
                    </audio>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
        <div className="flex justify-end border-t border-white border-opacity-10 px-8 py-3 gap-5">
          {/* <Button
                        title={"Preview"}
                        type={3}
                        onClick={() => setPreviewOpen((preState) => !preState)}
                    /> */}
          <div className="flex gap-5">
            <Button title={"Back"} type={2} onClick={() => dispatch(activeSectionAction(4))} />
            <Button
              title={"Next"}
              onClick={() => {
                handleSubmit();
                setSuccessOpen((preState) => !preState);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ArtistSelection = ({ noofArtists, handleLyricArtistChange, lyricsId, err }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (noofArtists === "1")
      setSelected({
        artist1: false,
        chorus: false,
        music: false,
      });
    if (noofArtists === "2")
      setSelected({
        artist1: false,
        artist2: false,
        chorus: false,
        music: false,
      });
    if (noofArtists === "3")
      setSelected({
        artist1: false,
        artist2: false,
        artist3: false,
        chorus: false,
        music: false,
      });
    if (noofArtists === "4")
      setSelected({
        artist1: false,
        artist2: false,
        artist3: false,
        artist4: false,
        chorus: false,
        music: false,
      });
  }, [noofArtists]);

  const handleSelect = (value) => {
    // if (typeof value === 'number') {
    //   setSelected((prevClicked) => ({
    //     ...prevClicked,
    //     [`artist${value}`]: !selected[`artist${value}`],
    //   }));
    // } else
    //   setSelected((prevClicked) => ({
    //     ...prevClicked,
    //     [`${value}`]: !selected[value],
    //   }));

    if (typeof value === "number") {
      setSelected({
        [`artist${value}`]: !selected[`artist${value}`],
      });
      handleLyricArtistChange(value, lyricsId);
    } else {
      setSelected({
        [`${value}`]: !selected[`${value}`],
      });
    }
  };

  return (
    <div className="text-white flex justify-between flex-col">
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className="rounded-lg flex justify-between cursor-pointer text-sm overflow-hidden "
      >
        {Array.from({ length: parseInt(noofArtists) }, (_, index) => index + 1).map((idx) => {
          return (
            <div
              key={lyricsId + noofArtists + idx}
              className={`${
                isHovering ? "text-opacity-60 text-white" : "text-opacity-0 text-white"
              } ${
                selected[`artist${idx}`]
                  ? "bg-opacity-60 bg-violet-500 text-opacity-100 text-white"
                  : "bg-opacity-30 bg-black"
              } h-full p-2 w-[100%] border-r-2 border-opacity-40 border-black `}
              onClick={() => handleSelect(idx)}
            >
              <p>Artist{idx}</p>
            </div>
          );
        })}

        {/* <div
          className={`${
            isHovering
              ? "text-opacity-60 text-white"
              : "text-opacity-0 text-white"
          } ${
            selected.chorus
              ? "bg-opacity-60 bg-violet-500 text-opacity-100 text-white"
              : "bg-opacity-30 bg-black"
          } h-full p-2 w-[100%] border-r-2 border-opacity-40 border-black`}
          onClick={() => handleSelect("chorus")}
        >
          <p>Chorus</p>
        </div> */}
        <div
          className={`${isHovering ? "text-opacity-60 text-white" : "text-opacity-0 text-white"} ${
            selected.music
              ? "bg-opacity-60 bg-violet-500 text-opacity-100 text-white"
              : "bg-opacity-30 bg-black"
          } h-full p-2 w-[100%] border-r-2 border-opacity-40 border-black`}
          onClick={() => handleSelect("music")}
        >
          <p>Music</p>
        </div>
      </div>
      <label className="text-xs">{err}</label>
    </div>
  );
};
