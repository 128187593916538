import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toastify from "../../../components/Universal/Toastify";
import {
  uploadAssetAction,
  TrackUpdateAction,
  AddTrackCategoryAction,
  AddTrackTagAction,
  ReadTagsAction,
  SaveTagsAction,
  ReadCatAction,
  SaveCatAction,
  TrackSaveAction,
  musicFileNameAction,
  musicUrlAction,
  musicIdAction,
  activeSectionAction,
  categoriesAction,
  tagsAction,
  updateTrackDetails,
  ResetTrackDetails,
  currentTrackDetails,
  CreateNewCategory,
} from "../../../store/TrackDetails/TractDetailAction";
import { mapCategoriesToOptions, mapTagsToOptions } from "../../../utils/HelperFuns";

export const UploadAssetHooks = () => {
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let trackData = useSelector((state) => state.trackReducer);
  const track = trackData.trackDetails;
  const [songBook, setSongBook] = useState({});

  useEffect(() => {
    let track = trackData.trackDetails;
    let { categories, tags } = trackData;
    let cat = mapCategoriesToOptions([...track.categories], [...categories]);
    let tag = mapTagsToOptions([...track.tags], [...tags]);

    setSongBook({
      category: cat,
      artist: track.noofArtists,
      tags: tag,
    });
  }, [trackData]);

  const initialTrackDetailValues = track._id
    ? {
        trackTitle: track.track_name,
        singerName: track.singer_name,
        movieName: track.movie_name,
        directorName: track.director_name,
      }
    : {
        trackTitle: "",
        singerName: "",
        movieName: "",
        directorName: "",
      };

  const [trackDetails, setTrackDetails] = useState(initialTrackDetailValues);

  let [error, setError] = useState({
    trackTitle: "",
    singerName: "",
    movieName: "",
    directorName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTrackDetails({ ...trackDetails, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const errors = {};
    if (trackDetails.trackTitle === "") {
      errors["trackTitle"] = "Please enter track title";
    }
    if (trackDetails.singerName === "") {
      errors["singerName"] = "Please enter singer name";
    }
    if (trackDetails.movieName === "") {
      errors["movieName"] = "Please enter movie name";
    }
    if (trackDetails.directorName === "") {
      errors["directorName"] = "Please enter director name";
    }
    console.log("errors", errors);
    console.log("error trackDetails", trackDetails);
    if (Object.keys(errors)?.length > 0) {
      setError({ ...error, ...errors });
    } else {
      updateTrackReq(trackDetails, trackData.id);
    }
  };

  const handleFileChange = (e) => {
    dispatch(ResetTrackDetails());
    dispatch(activeSectionAction(1));

    const fileData = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileData);
    if (!fileData) {
      alert("Please select a file to upload.");
      return;
    }
    dispatch(
      uploadAssetAction({
        apiPayloadRequest: formData,
        callback: uploadAsset,
      })
    );
  };

  const updateTrackReq = (data, id) => {
    let body = {
      _id: id,
      track_name: data.trackTitle,
      singer_name: data.singerName,
      movie_name: data.movieName,
      director_name: data.directorName,
    };
    dispatch(
      TrackUpdateAction({
        apiPayloadRequest: body,
        callback: (res) => {
          if (res.type == 1) {
            dispatch(currentTrackDetails(body));
            dispatch(activeSectionAction(2));
          }
        },
      })
    );
  };

  const AddTrackCategoryReq = (id, newOptions) => {
    // const fileData = e.target.files[0];
    // formData.append('file', fileData);

    let body = {
      _id: trackData.id,
      categoryObjId: id,
    };
    let payload = {
      body,
    };
    if (newOptions) {
      payload.newOptions = newOptions;
      payload.field = "categories";
    }

    dispatch(
      AddTrackCategoryAction({
        apiPayloadRequest: payload,
        callback: onSuccuss,
      })
    );
  };

  const RaedCatReq = () => {
    // const fileData = e.target.files[0];
    // formData.append('file', fileData);
    dispatch(
      ReadCatAction({
        apiPayloadRequest: {},
        callback: onReadCat,
      })
    );
  };

  const SaveCatReq = ({ value, label }) => {
    // const fileData = e.target.files[0];
    setIsDropdownLoading(true);
    let body = {
      category_name: value,
      category_description: label,
    };

    dispatch(CreateNewCategory({ apiPayloadRequest: body, callback: onCatSave }));
  };

  const AddTrackTagReq = (id, newOptions) => {
    // const fileData = e.target.files[0];
    let body = {
      _id: trackData.id,
      tagObjId: id,
    };

    let payload = {
      body,
    };
    if (newOptions) {
      payload.newOptions = newOptions;
      payload.field = "tags";
    }

    // formData.append('file', fileData);
    dispatch(
      AddTrackTagAction({
        apiPayloadRequest: payload,
        callback: onSuccuss,
      })
    );
  };

  const addArtist = (artist) => {
    const { trackDetails } = trackData;
    const body = {
      _id: trackData.id,
      track_name: trackDetails.track_name,
      singer_name: trackDetails.singer_name,
      movie_name: trackDetails.movie_name,
      noofArtists: artist,
      director_name: trackDetails.director_name,
      coverPhotoUrl: "",
    };
    dispatch(
      TrackUpdateAction({
        apiPayloadRequest: { body, payload: artist },
        callback: onSuccessArtist,
      })
    );
  };

  const onSuccessArtist = (response, payload) => {
    if (response.message === "Track Updated Sucessfully." && response.type === 1) {
      Toastify(response.message);
      dispatch(updateTrackDetails({ data: payload, field: "noofArtists" }));
      dispatch(activeSectionAction(3));
    }
  };

  const RaedTagsReq = (e) => {
    // const fileData = e.target.files[0];
    // formData.append('file', fileData);
    dispatch(
      ReadTagsAction({
        apiPayloadRequest: {},
        callback: onReadTags,
      })
    );
  };

  const SaveTagsReq = ({ value }) => {
    // const fileData = e.target.files[0];
    let body = {
      tag: value,
    }; // formData.append('file', fileData);
    dispatch(
      SaveTagsAction({
        apiPayloadRequest: body,
        callback: onSaveTag,
      })
    );
  };

  const uploadAsset = (response) => {
    navigate("/trackDetails");
    if (response.message === "No token provided. Please Re-Login." && response.type === 4) {
      Toastify(response.message);
    }
    if (response.url) {
      dispatch(musicFileNameAction(response.filename));
      dispatch(musicUrlAction(response.url));
      let body = {
        trackUrl: response.url,
      };
      dispatch(
        TrackSaveAction({
          apiPayloadRequest: body,
          callback: onSaveTrack,
        })
      );
    }
  };

  const onSuccuss = (response, payload, field) => {
    if (response.message === "No token provided. Please Re-Login." && response.type === 4) {
      Toastify(response.message);
    }

    if (payload || field) dispatch(updateTrackDetails({ data: payload, field }));
  };

  const onReadCat = (response) => {
    dispatch(categoriesAction(response.data));
  };

  const onSaveTrack = (response) => {
    dispatch(musicIdAction(response.data[0]._id));
    if (response.message === "Track Saved Sucessfully." && response.type === 1) {
      Toastify(response.message);
    }
    if (
      response.message === "Track already exists, Please contact Admin or Event Front Desk." &&
      response.type === 2
    ) {
      Toastify(response.message);
    }
  };

  const onCatSave = (response) => {
    if (response.type === 1) {
      RaedCatReq();
      AddTrackCategoryReq(response.data[0]._id);
    }
    setIsDropdownLoading(false);
  };

  const onReadTags = async (response) => {
    dispatch(tagsAction(response.data));
  };

  const onSaveTag = (response) => {
    console.log({ response });
    if (response.type === 1) {
      RaedTagsReq();
      AddTrackTagReq(response.data[0]._id);
    }
  };

  return {
    handleFileChange,
    updateTrackReq,
    AddTrackCategoryReq,
    RaedCatReq,
    SaveCatReq,
    AddTrackTagReq,
    RaedTagsReq,
    addArtist,
    SaveTagsReq,
    handleFormSubmit,
    handleInputChange,
    setSongBook,
    trackDetails,
    isDropdownLoading,
    songBook,
    trackData,
    track,
    error,
  };
};
